import React, { useState } from 'react';
import { Input, Button, Table, Space, message } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { createClient } from '../supabaseClient';

const CollectionSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    if (!searchTerm.trim()) return;

    setLoading(true);
    const supabase = createClient();
    const { data, error } = await supabase
      .from('collections')
      .select('*')
      .eq('is_public', true)
      .or(`name.ilike.%${searchTerm}%,type.ilike.%${searchTerm}%`)
      .order('name', { ascending: true });
    
    setLoading(false);
    if (error) {
      console.error('Error searching collections:', error);
      message.error('Error searching collections');
    } else {
      setSearchResults(data);
    }
  };

  const handleAddToPortfolio = async (collection) => {
    const supabase = createClient();
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) {
      message.error('User not authenticated');
      return;
    }

    const newCollection = {
      user_id: user.id,
      name: collection.name,
      description: collection.description,
      type: collection.type,
      start_year: collection.start_year,
      end_year: collection.end_year,
      is_government_issue: collection.is_government_issue,
      is_public: false,
      collection_type: 'user-copied',
      original_collection_id: collection.id
    };

    const { error } = await supabase
      .from('collections')
      .insert([newCollection]);
    
    if (error) {
      message.error('Error adding collection to portfolio');
      console.error('Error adding collection to portfolio:', error);
    } else {
      message.success('Collection added to portfolio successfully');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Start Year',
      dataIndex: 'start_year',
      key: 'start_year',
      render: (text) => text || 'N/A',
    },
    {
      title: 'End Year',
      dataIndex: 'end_year',
      key: 'end_year',
      render: (text) => text || 'N/A',
    },
    {
      title: 'Government Issue',
      dataIndex: 'is_government_issue',
      key: 'is_government_issue',
      render: (value) => value ? 'Yes' : 'No',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button
          icon={<PlusOutlined />}
          onClick={() => handleAddToPortfolio(record)}
        >
          Add to Portfolio
        </Button>
      ),
    },
  ];

  return (
    <div className="collection-search">
      <h2>Search Public Collections</h2>
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search for collections..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onPressEnter={handleSearch}
          style={{ width: 300 }}
        />
        <Button
          type="primary"
          icon={<SearchOutlined />}
          onClick={handleSearch}
          loading={loading}
        >
          Search
        </Button>
      </Space>
      <Table
        columns={columns}
        dataSource={searchResults}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default CollectionSearch;