import React, { useState, useEffect, useCallback } from 'react';
import { Table, Input, Button, Space, Select, Form, message, Modal, Tabs, Switch } from 'antd';
import { SearchOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { createClient } from '../supabaseClient';
import PriceTicker from './PriceTicker';
import AssetForm from './AssetForm';
import CollectionSearch from './CollectionSearch';
import './AssetManager.css';

const { TabPane } = Tabs;
const { Option } = Select;

const AssetManager = () => {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [savedSearches, setSavedSearches] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingAsset, setEditingAsset] = useState(null);
  const [metalPrices, setMetalPrices] = useState({});
  const [isAdvancedForm, setIsAdvancedForm] = useState(false);
  const [form] = Form.useForm();

  const fetchAssets = useCallback(async () => {
    setLoading(true);
    const supabase = createClient();
    const { data: { user } } = await supabase.auth.getUser();
    let query = supabase.from('user_assets').select('*').eq('user_id', user.id);

    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        query = query.ilike(key, `%${value}%`);
      }
    });

    const { data, error } = await query;
    
    if (error) {
      console.error('Error fetching assets:', error);
      message.error('Failed to fetch assets');
    } else {
      setAssets(data);
    }
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    fetchAssets();
    fetchSavedSearches();
    fetchMetalPrices();
  }, [fetchAssets]);

  const fetchSavedSearches = async () => {
    const supabase = createClient();
    const { data: { user } } = await supabase.auth.getUser();
    const { data, error } = await supabase
      .from('saved_searches')
      .select('*')
      .eq('user_id', user.id);

    if (error) {
      console.error('Error fetching saved searches:', error);
    } else {
      setSavedSearches(data);
    }
  };

  const fetchMetalPrices = async () => {
    const supabase = createClient();
    const { data, error } = await supabase
      .from('metal_prices')
      .select('*')
      .order('date', { ascending: false })
      .limit(4);
    
    if (error) {
      console.error('Error fetching metal prices:', error);
    } else {
      const prices = {};
      data.forEach(price => {
        prices[price.metal.toLowerCase()] = price.price;
      });
      setMetalPrices(prices);
    }
  };

  const calculateMeltValue = (asset) => {
    const { metal, weight, purity, weight_unit } = asset;
    const price = metalPrices[metal.toLowerCase()];
    if (!price) return 'N/A';

    let weightInOunces = weight;
    if (weight_unit === 'g') {
      weightInOunces = weight / 31.1035; // Convert grams to troy ounces
    }

    const meltValue = price * weightInOunces * purity;
    return meltValue.toFixed(2);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setFilters(prev => ({ ...prev, [dataIndex]: selectedKeys[0] }));
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setFilters(prev => ({ ...prev, [dataIndex]: undefined }));
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  });

  const columns = [
    {
      title: 'Metal',
      dataIndex: 'metal',
      key: 'metal',
      ...getColumnSearchProps('metal'),
    },
    {
      title: 'Asset Type',
      dataIndex: 'asset_type',
      key: 'asset_type',
      ...getColumnSearchProps('asset_type'),
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
      sorter: (a, b) => a.weight - b.weight,
      render: (text, record) => `${text} ${record.weight_unit}`,
    },
    {
      title: 'Purity',
      dataIndex: 'purity',
      key: 'purity',
      sorter: (a, b) => a.purity - b.purity,
      render: (text) => text ? `${(text * 100).toFixed(2)}%` : 'N/A',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: 'Purchase Date',
      dataIndex: 'purchase_date',
      key: 'purchase_date',
      sorter: (a, b) => new Date(a.purchase_date) - new Date(b.purchase_date),
    },
    {
      title: 'Purchase Price',
      dataIndex: 'purchase_price',
      key: 'purchase_price',
      sorter: (a, b) => a.purchase_price - b.purchase_price,
      render: (text, record) => text ? `${text} ${record.currency}` : 'N/A',
    },
    {
      title: 'Melt Value',
      dataIndex: 'melt_value',
      key: 'melt_value',
      sorter: (a, b) => calculateMeltValue(a) - calculateMeltValue(b),
      render: (_, record) => {
        const meltValue = calculateMeltValue(record);
        return meltValue !== 'N/A' ? `${meltValue} ${record.currency}` : 'N/A';
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleEdit(record)}>Edit</Button>
          <Button onClick={() => handleDelete(record.id)}>Delete</Button>
        </Space>
      ),
    },
  ];

  const handleAddEditAsset = async (values) => {
    const supabase = createClient();
    const { data: { user } } = await supabase.auth.getUser();

    if (editingAsset) {
      const { error } = await supabase
        .from('user_assets')
        .update({ ...values, user_id: user.id })
        .eq('id', editingAsset.id);

      if (error) {
        message.error('Failed to update asset');
      } else {
        message.success('Asset updated successfully');
        setEditingAsset(null);
      }
    } else {
      const { error } = await supabase
        .from('user_assets')
        .insert([{ ...values, user_id: user.id }]);

      if (error) {
        message.error('Failed to add asset');
      } else {
        message.success('Asset added successfully');
      }
    }

    setIsModalVisible(false);
    fetchAssets();
  };

  const handleEdit = (asset) => {
    setEditingAsset(asset);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    const supabase = createClient();
    const { error } = await supabase
      .from('user_assets')
      .delete()
      .eq('id', id);

    if (error) {
      message.error('Failed to delete asset');
    } else {
      message.success('Asset deleted successfully');
      fetchAssets();
    }
  };

  const handleSaveSearch = async (values) => {
    const supabase = createClient();
    const { data: { user } } = await supabase.auth.getUser();
    const { error } = await supabase
      .from('saved_searches')
      .insert([
        {
          user_id: user.id,
          name: values.name,
          filters: JSON.stringify(filters),
        },
      ]);

    if (error) {
      console.error('Error saving search:', error);
      message.error('Failed to save search');
    } else {
      message.success('Search saved successfully');
      fetchSavedSearches();
    }
  };

  const handleApplySavedSearch = async (searchId) => {
    const search = savedSearches.find(s => s.id === searchId);
    if (search) {
      setFilters(JSON.parse(search.filters));
    }
  };

  const toggleFormType = () => {
    setIsAdvancedForm(!isAdvancedForm);
  };

  return (
    <div className="asset-manager">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Assets" key="1">
          <PriceTicker metalPrices={Object.entries(metalPrices).map(([metal, price]) => ({ metal, price }))} />
          <Space style={{ marginBottom: 16 }}>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)}>
              Add Asset
            </Button>
            <Button icon={<EditOutlined />} onClick={() => { /* Navigate to Bulk Edit page */ }}>
              Bulk Edit Assets
            </Button>
            <Switch
              checkedChildren="Advanced"
              unCheckedChildren="Basic"
              checked={isAdvancedForm}
              onChange={toggleFormType}
            />
          </Space>
          <Space direction="vertical" style={{ width: '100%', marginBottom: 16 }}>
            <Form form={form} layout="inline" onFinish={handleSaveSearch}>
              <Form.Item name="name" rules={[{ required: true, message: 'Please input a name for this search' }]}>
                <Input placeholder="Save current search as..." />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save Search
                </Button>
              </Form.Item>
            </Form>
            <Select
              style={{ width: 200 }}
              placeholder="Apply saved search"
              onChange={handleApplySavedSearch}
            >
              {savedSearches.map(search => (
                <Option key={search.id} value={search.id}>{search.name}</Option>
              ))}
            </Select>
          </Space>
          <Table
            columns={columns}
            dataSource={assets}
            loading={loading}
            rowKey="id"
          />
        </TabPane>
        <TabPane tab="Collections" key="2">
          <CollectionSearch />
        </TabPane>
      </Tabs>
      <Modal
        title={editingAsset ? "Edit Asset" : "Add Asset"}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setEditingAsset(null);
        }}
        footer={null}
      >
        <AssetForm
          initialValues={editingAsset}
          onSubmit={handleAddEditAsset}
          formType={isAdvancedForm ? 'advanced' : 'basic'}
        />
      </Modal>
    </div>
  );
};

export default AssetManager;