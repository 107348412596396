import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { isSessionValid, setupAuthListener } from './supabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkSessionValidity = useCallback(async () => {
    const valid = await isSessionValid();
    setIsAuthenticated(valid);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    let authListener = null;

    const setupAuth = async () => {
      authListener = setupAuthListener((event, session) => {
        if (event === 'SIGNED_IN') {
          setIsAuthenticated(true);
        } else if (event === 'SIGNED_OUT') {
          setIsAuthenticated(false);
        }
      });

      await checkSessionValidity();
    };

    setupAuth();

    return () => {
      if (authListener && typeof authListener.unsubscribe === 'function') {
        authListener.unsubscribe();
      } else if (authListener && authListener.data && authListener.data.subscription && typeof authListener.data.subscription.unsubscribe === 'function') {
        authListener.data.subscription.unsubscribe();
      }
    };
  }, [checkSessionValidity]);

  const login = useCallback(() => {
    setIsAuthenticated(true);
  }, []);

  const logout = useCallback(() => {
    setIsAuthenticated(false);
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);