import React, { useState } from 'react';
import { signUpWithEmail, createBrowserSupabaseClient } from '../supabaseClient';
import { addContactToSendFox } from '../utils/sendfox-api';

function Signup({ openBetaToS }) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    stackingExperience: '',
    terms: false
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [sendfoxSuccess, setSendfoxSuccess] = useState(false);
  const [lastSignupAttempt, setLastSignupAttempt] = useState(0);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const validateForm = () => {
    if (!formData.firstName.trim()) {
      setError('First Name is required');
      return false;
    }
    if (!formData.lastName.trim()) {
      setError('Last Name is required');
      return false;
    }
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      setError('Valid email is required');
      return false;
    }
    if (!formData.password.trim() || formData.password.length < 6) {
      setError('Password must be at least 6 characters long');
      return false;
    }
    if (!formData.stackingExperience) {
      setError('Please select your stacking experience');
      return false;
    }
    if (!formData.terms) {
      setError('You must agree to the terms and conditions');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    if (!validateForm()) {
      return;
    }

    const now = Date.now();
    if (now - lastSignupAttempt < 60000) { // 1 minute cooldown
      setError('Please wait a moment before trying again.');
      return;
    }

    setLoading(true);
    setLastSignupAttempt(now);

    try {
      // Create user in Supabase
      const { authData, profileData } = await signUpWithEmail(formData.email, formData.password);

      if (!authData || !profileData) {
        throw new Error('Failed to create user account or profile');
      }

      console.log('User account and profile created successfully:', { authData, profileData });

      // Update the profile with additional information
      const supabase = createBrowserSupabaseClient();
      const { error: updateError } = await supabase
        .from('profiles')
        .update({
          first_name: formData.firstName,
          last_name: formData.lastName,
          stacking_experience: formData.stackingExperience
        })
        .eq('id', authData.user.id);

      if (updateError) {
        console.error('Error updating profile:', updateError);
        throw new Error('Failed to update user profile');
      }

      // Try to submit to SendFox
      let sendfoxSuccess = false;
      try {
        await addContactToSendFox(formData.email, formData.firstName, formData.lastName);
        sendfoxSuccess = true;
        console.log('SendFox signup successful');
      } catch (sendfoxError) {
        console.error('Error submitting to SendFox:', sendfoxError);
        // We don't throw this error, as we want the signup process to continue
      }

      setSuccess(true);
      setSendfoxSuccess(sendfoxSuccess);
      console.log(sendfoxSuccess ? 'Signup successful for both Supabase and SendFox' : 'Signup successful for Supabase, but failed for SendFox');
      
      // Reset form
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        stackingExperience: '',
        terms: false
      });
    } catch (error) {
      console.error('Error:', error);
      if (error.message.includes('rate limit') || error.status === 429) {
        setError('Too many signup attempts. Please try again in a few minutes.');
      } else {
        setError(error.message || 'There was an error submitting your form. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return (
      <section className="signup section" id="signup">
        <div className="container">
          <h2 className="glow-text">Thank You for Signing Up!</h2>
          <p>Your account has been created successfully. {!sendfoxSuccess && "However, we couldn't add you to our mailing list at this time. We'll make sure to include you soon!"}</p>
        </div>
      </section>
    );
  }

  return (
    <section className="signup section" id="signup">
      <div className="container">
        <h2 className="glow-text">Ready to Join Our Beta?</h2>
        {error && <p className="error-message">{error}</p>}
        <form id="beta-signup-form" className="neon-border" onSubmit={handleSubmit}>
          <input type="text" id="firstName" name="firstName" placeholder="First Name" required value={formData.firstName} onChange={handleChange} />
          <input type="text" id="lastName" name="lastName" placeholder="Last Name" required value={formData.lastName} onChange={handleChange} />
          <input type="email" id="email" name="email" placeholder="Your Email" required value={formData.email} onChange={handleChange} />
          <input type="password" id="password" name="password" placeholder="Choose a Password" required value={formData.password} onChange={handleChange} />
          <select name="stackingExperience" required value={formData.stackingExperience} onChange={handleChange}>
            <option value="">Select Stacking Experience Level</option>
            <option value="beginner">Beginner (0-$10,000)</option>
            <option value="intermediate">Intermediate ($10,001-$50,000)</option>
            <option value="advanced">Advanced ($50,001-$250,000)</option>
            <option value="expert">Expert ($250,001+)</option>
          </select>
          <label>
            <input type="checkbox" name="terms" required checked={formData.terms} onChange={handleChange} />
            I agree to the <button onClick={(e) => { e.preventDefault(); openBetaToS(); }} className="tos-link">terms and conditions</button>
          </label>
          <button type="submit" className="cta-button primary" disabled={loading}>
            {loading ? 'Submitting...' : 'Join the Beta'}
          </button>
        </form>
        <p className="privacy-note">We respect your privacy. Your data is safe with us.</p>
      </div>
    </section>
  );
}

export default Signup;