import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../images/metalstackers-logo-transparent-cropped.png';

function HeroHeader() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleCTAClick = () => {
    if (location.pathname !== '/') {
      // If not on the home page, navigate there first
      navigate('/', { state: { scrollToSignup: true } });
    } else {
      // If already on home page, scroll to signup
      document.getElementById('signup')?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="hero-header">
      <div className="hero">
        <video autoPlay loop muted playsInline id="bgVideo">
          <source src="/videobg.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay">
          <header>
            <img src={logo} alt="MetalStackers Logo" className="logo" loading="lazy" />
          </header>
          <h1 className="glow-text">Unlock the Future of Metal Investing</h1>
          <p>Join our exclusive beta and be the first to experience the most advanced metal portfolio tracker.</p>
          <button className="cta-button primary" onClick={handleCTAClick}>
            Request Your Invite
          </button>
        </div>
      </div>
    </section>
  );
}

export default HeroHeader;