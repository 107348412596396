import React, { useState, useEffect, useCallback } from 'react';
import { createBrowserSupabaseClient } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import './CreateCollection.css';

function CreateCollection() {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    type: '',
    collectionType: '',
    startYear: '',
    endYear: '',
    totalCoins: '',
    totalValue: '',
    completeness: '',
    isPublic: false,
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const calculateCompleteness = useCallback(() => {
    const { name, description, type, collectionType, startYear, endYear, totalCoins, totalValue } = formData;
    const fields = [name, description, type, collectionType, startYear, endYear, totalCoins, totalValue];
    const filledFields = fields.filter(field => field !== '').length;
    return Math.round((filledFields / fields.length) * 100);
  }, [formData]);

  useEffect(() => {
    const completeness = calculateCompleteness();
    setFormData(prevData => ({ ...prevData, completeness: completeness.toString() }));
  }, [calculateCompleteness]);

  const validateStep = () => {
    switch(step) {
      case 1:
        return formData.name.trim() !== '' && formData.description.trim() !== '';
      case 2:
        return formData.type !== '' && formData.collectionType !== '';
      case 3:
        return formData.startYear !== '' && formData.endYear !== '';
      default:
        return true;
    }
  };

  const handleNextStep = () => {
    if (validateStep()) {
      setStep(prevStep => prevStep + 1);
    } else {
      setError('Please fill in all required fields before proceeding.');
    }
  };

  const handlePrevStep = () => {
    setStep(prevStep => prevStep - 1);
    setError('');
  };

  const validateForm = () => {
    if (formData.name.trim() === '') {
      setError('Collection name is required.');
      return false;
    }
    if (formData.type === '') {
      setError('Collection type is required.');
      return false;
    }
    if (formData.collectionType === '') {
      setError('Specific collection type is required.');
      return false;
    }
    if (formData.startYear && isNaN(parseInt(formData.startYear))) {
      setError('Start year must be a valid number.');
      return false;
    }
    if (formData.endYear && isNaN(parseInt(formData.endYear))) {
      setError('End year must be a valid number.');
      return false;
    }
    if (formData.totalCoins && isNaN(parseInt(formData.totalCoins))) {
      setError('Total items must be a valid number.');
      return false;
    }
    if (formData.totalValue && isNaN(parseFloat(formData.totalValue))) {
      setError('Total value must be a valid number.');
      return false;
    }
    return true;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    setError('');

    try {
      const supabase = createBrowserSupabaseClient();
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      
      if (userError) {
        throw new Error('User not authenticated');
      }

      const { error } = await supabase
        .from('user_collections')
        .insert([
          { 
            user_id: user.id, 
            name: formData.name, 
            description: formData.description, 
            type: formData.type,
            collection_type: formData.collectionType,
            start_year: formData.startYear ? parseInt(formData.startYear) : null,
            end_year: formData.endYear ? parseInt(formData.endYear) : null,
            is_public: formData.isPublic,
            total_coins: formData.totalCoins ? parseInt(formData.totalCoins) : 0,
            total_value: formData.totalValue ? parseFloat(formData.totalValue) : 0,
            completeness: formData.completeness ? parseFloat(formData.completeness) : 0,
            is_archived: false,
            version: 1
          }
        ]);

      if (error) {
        console.error('Supabase error:', error);
        if (error.code === '23514') {
          setError('Invalid collection type. Please check your selection and try again.');
        } else {
          throw error;
        }
      } else {
        navigate('/profile');
      }
    } catch (error) {
      console.error('Error creating collection:', error);
      setError(`Failed to create collection: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }

  const renderStep = () => {
    switch(step) {
      case 1:
        return (
          <>
            <div className="form-group">
              <label htmlFor="name">Collection Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                placeholder="Enter collection name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Describe your collection"
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="form-group">
              <label htmlFor="type">Type</label>
              <select
                id="type"
                name="type"
                value={formData.type}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a type</option>
                <option value="numismatic">Numismatic</option>
                <option value="bullion">Bullion</option>
                <option value="mixed">Mixed</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="collectionType">Collection Type</label>
              <select
                id="collectionType"
                name="collectionType"
                value={formData.collectionType}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a collection type</option>
                <option value="coin">Coin</option>
                <option value="round">Round</option>
                <option value="bar">Bar</option>
                <option value="mixed">Mixed</option>
              </select>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="form-group date-range">
              <label htmlFor="startYear">Start Year</label>
              <input
                type="number"
                id="startYear"
                name="startYear"
                value={formData.startYear}
                onChange={handleInputChange}
                placeholder="Enter start year"
              />
              <label htmlFor="endYear">End Year</label>
              <input
                type="number"
                id="endYear"
                name="endYear"
                value={formData.endYear}
                onChange={handleInputChange}
                placeholder="Enter end year (leave blank if ongoing)"
              />
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="form-group">
              <label htmlFor="totalCoins">Total Items</label>
              <input
                type="number"
                id="totalCoins"
                name="totalCoins"
                value={formData.totalCoins}
                onChange={handleInputChange}
                placeholder="Enter total number of items"
              />
            </div>
            <div className="form-group">
              <label htmlFor="totalValue">Total Value</label>
              <input
                type="number"
                id="totalValue"
                name="totalValue"
                value={formData.totalValue}
                onChange={handleInputChange}
                step="0.01"
                placeholder="Enter total value"
              />
            </div>
            <div className="form-group toggle-switch">
              <label htmlFor="isPublic">Make this collection public</label>
              <label className="switch">
                <input
                  type="checkbox"
                  id="isPublic"
                  name="isPublic"
                  checked={formData.isPublic}
                  onChange={handleInputChange}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <section className="create-collection section">
      <div className="container">
        <h2 className="glow-text">Create New Collection</h2>
        <div className="progress-bar">
          <div className="progress" style={{width: `${(step / 4) * 100}%`}}></div>
        </div>
        <form onSubmit={handleSubmit} className="collection-form">
          {renderStep()}
          {error && <p className="error-message">{error}</p>}
          <div className="form-nav">
            {step > 1 && (
              <button type="button" onClick={handlePrevStep} className="prev-button">
                Previous
              </button>
            )}
            {step < 4 ? (
              <button type="button" onClick={handleNextStep} className="next-button">
                Next
              </button>
            ) : (
              <button type="submit" className="cta-button glow-button" disabled={loading}>
                {loading ? 'Creating...' : 'Create Collection'}
              </button>
            )}
          </div>
        </form>
        <div className="completeness">
          <p>Form Completeness: {formData.completeness}%</p>
        </div>
      </div>
    </section>
  );
}

export default CreateCollection;