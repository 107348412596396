import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, Tree, Card, Col, Row, Spin } from 'antd';
import { createClient } from '../supabaseClient';
import './AssetCategoryBrowser.css';

const { TabPane } = Tabs;
const { Meta } = Card;

// Move buildCategoryTree outside the component
const buildCategoryTree = (categories, parentId = null) => {
  return categories
    .filter(category => category.parent_id === parentId)
    .map(category => ({
      title: category.name,
      key: category.id,
      children: buildCategoryTree(categories, category.id)
    }));
};

const AssetCategoryBrowser = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCategories = useCallback(async () => {
    const supabase = createClient();
    const { data, error } = await supabase
      .from('asset_categories')
      .select('*');

    if (error) {
      console.error('Error fetching categories:', error);
    } else {
      const treeData = buildCategoryTree(data);
      setCategories(treeData);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const fetchAssetsByCategory = useCallback(async (categoryId) => {
    setLoading(true);
    const supabase = createClient();
    const { data, error } = await supabase
      .from('public_assets')
      .select('*')
      .eq('category_id', categoryId);

    if (error) {
      console.error('Error fetching assets:', error);
    } else {
      setAssets(data);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchAssetsByCategory(selectedCategory);
    }
  }, [selectedCategory, fetchAssetsByCategory]);

  const handleCategorySelect = (selectedKeys) => {
    if (selectedKeys.length > 0) {
      setSelectedCategory(selectedKeys[0]);
    }
  };

  return (
    <div className="asset-category-browser">
      <h2>Browse Assets by Category</h2>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Categories" key="1">
          <Row gutter={16}>
            <Col span={8}>
              <Tree
                treeData={categories}
                onSelect={handleCategorySelect}
              />
            </Col>
            <Col span={16}>
              {loading ? (
                <Spin size="large" />
              ) : (
                <Row gutter={[16, 16]}>
                  {assets.map((asset) => (
                    <Col xs={24} sm={12} md={8} lg={6} key={asset.id}>
                      <Card
                        hoverable
                        cover={<img alt={asset.name} src={asset.image_url || 'https://via.placeholder.com/150'} />}
                      >
                        <Meta title={asset.name} description={`${asset.year} ${asset.metal} - ${asset.weight}oz`} />
                        <p>Price: ${asset.price}</p>
                      </Card>
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Popular" key="2">
          {/* Implement popular assets section */}
        </TabPane>
        <TabPane tab="New Arrivals" key="3">
          {/* Implement new arrivals section */}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AssetCategoryBrowser;