import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography, Progress, Button, Modal, Form, Input, InputNumber, Select } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { createClient } from '../supabaseClient';
import './CollectionGrid.css';

const { Title, Text } = Typography;
const { Option } = Select;

const CollectionGrid = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingCollection, setEditingCollection] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchCollections();
  }, []);

  const fetchCollections = async () => {
    setLoading(true);
    const supabase = createClient();
    const { data: { user } } = await supabase.auth.getUser();
    const { data, error } = await supabase
      .from('user_collections')
      .select('*')
      .eq('user_id', user.id);

    if (error) {
      console.error('Error fetching collections:', error);
    } else {
      setCollections(data);
    }
    setLoading(false);
  };

  const showModal = (collection = null) => {
    setEditingCollection(collection);
    if (collection) {
      form.setFieldsValue(collection);
    } else {
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingCollection(null);
  };

  const handleSubmit = async (values) => {
    const supabase = createClient();
    const { data: { user } } = await supabase.auth.getUser();
    if (editingCollection) {
      const { error } = await supabase
        .from('user_collections')
        .update({ ...values, user_id: user.id })
        .eq('id', editingCollection.id);

      if (error) {
        console.error('Error updating collection:', error);
      } else {
        fetchCollections();
      }
    } else {
      const { error } = await supabase
        .from('user_collections')
        .insert({ ...values, user_id: user.id });

      if (error) {
        console.error('Error creating collection:', error);
      } else {
        fetchCollections();
      }
    }
    setIsModalVisible(false);
  };

  return (
    <div className="collection-grid">
      <Title level={2}>My Collections</Title>
      {loading ? (
        <div>Loading collections...</div>
      ) : (
        <Row gutter={[16, 16]}>
          {collections.map((collection) => (
            <Col xs={24} sm={12} md={8} lg={6} key={collection.id}>
              <Card
                hoverable
                cover={
                  <img
                    alt={collection.name}
                    src={collection.cover_image || 'https://via.placeholder.com/300x200'}
                  />
                }
                actions={[
                  <Link to={`/collection/${collection.id}`}>
                    <Button type="primary">View Details</Button>
                  </Link>,
                  <Button icon={<EditOutlined />} onClick={() => showModal(collection)} />
                ]}
              >
                <Card.Meta
                  title={collection.name}
                  description={
                    <>
                      <Text>{collection.description}</Text>
                      <Progress
                        percent={Math.round(collection.completeness)}
                        status="active"
                        strokeColor={{
                          '0%': '#108ee9',
                          '100%': '#87d068',
                        }}
                      />
                      <Text>{`${collection.total_coins} coins`}</Text>
                      <div>
                        <Text strong>Type: </Text>
                        <Text>{collection.collection_type}</Text>
                      </div>
                      <div>
                        <Text strong>Total Value: </Text>
                        <Text>${collection.total_value.toFixed(2)}</Text>
                      </div>
                    </>
                  }
                />
              </Card>
            </Col>
          ))}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card
              hoverable
              className="add-collection-card"
              onClick={() => showModal()}
            >
              <div className="add-collection-content">
                <Title level={3}>Create New Collection</Title>
                <Button type="primary" shape="circle" icon={<PlusOutlined />} size="large" />
              </div>
            </Card>
          </Col>
        </Row>
      )}
      <Modal
        title={editingCollection ? "Edit Collection" : "Create New Collection"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item name="name" label="Collection Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="type" label="Type">
            <Input />
          </Form.Item>
          <Form.Item name="collection_type" label="Collection Type">
            <Select>
              <Option value="coin">Coin</Option>
              <Option value="banknote">Banknote</Option>
              <Option value="stamp">Stamp</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item name="start_year" label="Start Year">
            <InputNumber />
          </Form.Item>
          <Form.Item name="end_year" label="End Year">
            <InputNumber />
          </Form.Item>
          <Form.Item name="total_coins" label="Total Coins" rules={[{ required: true }]}>
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="total_value" label="Total Value">
            <InputNumber min={0} step={0.01} />
          </Form.Item>
          <Form.Item name="completeness" label="Completeness (%)">
            <InputNumber min={0} max={100} />
          </Form.Item>
          <Form.Item name="is_public" label="Public Collection" valuePropName="checked">
            <Select>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingCollection ? "Update Collection" : "Create Collection"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CollectionGrid;