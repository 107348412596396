import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { createBrowserSupabaseClient } from '../supabaseClient';
import './CollectionDetail.css';

function CollectionDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [collection, setCollection] = useState(null);
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updatingStats, setUpdatingStats] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isOwner, setIsOwner] = useState(false);
  const [refreshCoins, setRefreshCoins] = useState(0);

  const fetchCollectionDetails = useCallback(async () => {
    setLoading(true);
    setError('');

    try {
      const supabase = createBrowserSupabaseClient();
      const { data: { user } } = await supabase.auth.getUser();

      // First, try to fetch from user_collections
      let { data, error } = await supabase
        .from('user_collections')
        .select('*')
        .eq('id', id)
        .single();

      // If not found in user_collections, try public_collections
      if (error && error.code === 'PGRST116') {
        ({ data, error } = await supabase
          .from('public_collections')
          .select('*')
          .eq('id', id)
          .single());
      }

      if (error) throw error;

      setCollection(data);
      setIsOwner(user && data.user_id === user.id);
    } catch (error) {
      console.error('Error fetching collection details:', error);
      setError('Failed to fetch collection details. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [id]);

  const updateCollectionStatistics = useCallback(async (coinsData) => {
    setUpdatingStats(true);
    const totalCoins = coinsData.length;
    const totalValue = coinsData.reduce((sum, coin) => sum + (coin.current_value || 0), 0);
    const completeness = (totalCoins / (collection?.total_coins || 1)) * 100;

    try {
      const supabase = createBrowserSupabaseClient();
      const { error } = await supabase
        .from(collection.is_public ? 'public_collections' : 'user_collections')
        .update({
          total_coins: totalCoins,
          total_value: totalValue,
          completeness: completeness
        })
        .eq('id', id);

      if (error) throw error;

      setCollection(prev => ({
        ...prev,
        total_coins: totalCoins,
        total_value: totalValue,
        completeness: completeness
      }));
    } catch (error) {
      console.error('Error updating collection statistics:', error);
      setError('Failed to update collection statistics. Please refresh the page.');
    } finally {
      setUpdatingStats(false);
    }
  }, [id, collection]);

  const fetchCoins = useCallback(async () => {
    setLoading(true);
    setError('');

    try {
      const supabase = createBrowserSupabaseClient();
      const { data, error } = await supabase
        .from('public_assets')
        .select('*')
        .eq('collection_id', id)
        .order('year', { ascending: true });

      if (error) throw error;

      setCoins(data);
      updateCollectionStatistics(data);
    } catch (error) {
      console.error('Error fetching coins:', error);
      setError('Failed to fetch coins. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [id, updateCollectionStatistics]);

  useEffect(() => {
    fetchCollectionDetails();
  }, [fetchCollectionDetails]);

  useEffect(() => {
    fetchCoins();
  }, [fetchCoins, refreshCoins]);

  useEffect(() => {
    if (location.state && location.state.newCoinAdded) {
      setRefreshCoins(prev => prev + 1);
      setSuccessMessage('New coin added successfully!');
      setTimeout(() => setSuccessMessage(''), 5000);
    }
  }, [location.state]);

  const handleCopyCollection = async () => {
    try {
      const supabase = createBrowserSupabaseClient();
      const { data: { user } } = await supabase.auth.getUser();

      if (!user) {
        navigate('/login');
        return;
      }

      const { data, error } = await supabase
        .from('user_collections')
        .insert([
          {
            user_id: user.id,
            name: `Copy of ${collection.name}`,
            description: collection.description,
            type: collection.type,
            collection_type: collection.collection_type,
            start_year: collection.start_year,
            end_year: collection.end_year,
            is_public: false,
            original_collection_id: collection.id,
            total_coins: collection.total_coins,
            total_value: collection.total_value,
            completeness: collection.completeness,
            is_archived: false,
            version: 1
          }
        ])
        .select()
        .single();

      if (error) throw error;

      navigate(`/collections/${data.id}`);
    } catch (error) {
      console.error('Error copying collection:', error);
      setError('Failed to copy collection. Please try again.');
    }
  };

  if (loading) return <div className="loading">Loading collection details...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!collection) return <div className="error">Collection not found.</div>;

  return (
    <section className="collection-detail section">
      <div className="container">
        <h2 className="glow-text">{collection.name}</h2>
        {successMessage && <div className="success-message">{successMessage}</div>}
        <p>{collection.description}</p>
        <div className="collection-info">
          <p>Type: {collection.type}</p>
          <p>Collection Type: {collection.collection_type}</p>
          <p>Years: {collection.start_year} - {collection.end_year || 'Present'}</p>
          <p>Total Coins: {collection.total_coins || 0}</p>
          <p>Total Value: ${(collection.total_value || 0).toFixed(2)}</p>
          <p>Completeness: {collection.completeness.toFixed(2)}%</p>
          <p>{collection.is_public ? 'Public Collection' : 'Personal Collection'}</p>
          {collection.is_archived && <p>Archived</p>}
          <p>Version: {collection.version}</p>
        </div>
        {updatingStats && <div className="loading">Updating collection statistics...</div>}
        {!isOwner && collection.is_public && (
          <button onClick={handleCopyCollection} className="cta-button glow-button">
            Copy to My Collections
          </button>
        )}
        {isOwner && (
          <Link to={`/collections/${id}/add-coin`} className="cta-button glow-button">
            Add Coin to Collection
          </Link>
        )}
        <h3>Coins in this collection:</h3>
        {coins.length === 0 ? (
          <p>No coins have been added to this collection yet.</p>
        ) : (
          <div className="coins-grid">
            {coins.map((coin) => (
              <div key={coin.id} className="coin-card">
                <Link to={`/coins/${coin.id}`}>
                  <h4>{coin.year} {coin.denomination}</h4>
                  <p>Metal: {coin.metal}</p>
                  <p>Weight: {coin.weight}g</p>
                  <p>Purity: {coin.purity}</p>
                  {coin.mintage && <p>Mintage: {coin.mintage.toLocaleString()}</p>}
                  {coin.mint_mark && <p>Mint Mark: {coin.mint_mark}</p>}
                  <p>{coin.is_proof ? 'Proof' : 'Bullion'}</p>
                  {coin.design_description && <p>Design: {coin.design_description}</p>}
                  {coin.image_obverse && <img src={coin.image_obverse} alt="Coin Obverse" className="coin-image" />}
                  {coin.image_reverse && <img src={coin.image_reverse} alt="Coin Reverse" className="coin-image" />}
                </Link>
              </div>
            ))}
          </div>
        )}
        <Link to="/collections" className="cta-button glow-button">Back to Collections</Link>
      </div>
    </section>
  );
}

export default CollectionDetail;