import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import './MobileMenu.css';
import HeroHeader from './components/HeroHeader';
import Features from './components/Features';
import Benefits from './components/Benefits';
import Testimonials from './components/Testimonials';
import Roadmap from './components/Roadmap';
import Signup from './components/Signup';
import Footer from './components/Footer';
import BetaToS from './components/BetaToS';
import AboutUs from './components/AboutUs';
import Contribute from './components/Contribute';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Support from './components/Support';
import Login from './components/Login';
import UserSignup from './components/UserSignup';
import ApiServices from './components/ApiServices';
import { initParticles } from './particles';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import AssetManager from './components/AssetManager';
import UserProfile from './components/UserProfile';
import AuthCallback from './components/AuthCallback';
import CreateCollection from './components/CreateCollection';
import LinkAccounts from './components/LinkAccounts';
import CollectionList from './components/CollectionList';
import CollectionDetail from './components/CollectionDetail';
import HistoricMetalPrices from './components/HistoricMetalPrices';
import AddAssetToCollection from './components/AddAssetToCollection';
import AssetDetails from './components/AssetDetails';
import QuickAddAsset from './components/QuickAddAsset';
import BulkEditAssets from './components/BulkEditAssets';
import CollectionOrganizer from './components/CollectionOrganizer';
import CollectionGrid from './components/CollectionGrid';
import ShareCollection from './components/ShareCollection';
import AutoSuggestSearch from './components/AutoSuggestSearch';
import AdvancedSearch from './components/AdvancedSearch';
import VisualSearch from './components/VisualSearch';
import AssetCategoryBrowser from './components/AssetCategoryBrowser';
import AssetRecommendations from './components/AssetRecommendations';
import AddToMyCollections from './components/AddToMyCollections';
import PriceTicker from './components/PriceTicker';
import { signOut, isSessionValid } from './supabaseClient';
import { AuthProvider, useAuth } from './AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import { logError } from './utils/errorLogging';

function AppContent() {
  const [showBetaToS, setShowBetaToS] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showContribute, setShowContribute] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, login, logout } = useAuth();
  const particlesInitialized = useRef(false);

  useEffect(() => {
    const handleGlobalError = (event) => {
      event.preventDefault();
      const errorMessage = event.error ? event.error.message : 'An unexpected error occurred';
      setGlobalError(errorMessage);
      logError(event.error || new Error(errorMessage));
    };

    window.addEventListener('error', handleGlobalError);
    return () => window.removeEventListener('error', handleGlobalError);
  }, []);

  useEffect(() => {
    const initializeParticles = () => {
      if (!particlesInitialized.current) {
        const particlesElement = document.getElementById('particles-js');
        if (particlesElement) {
          try {
            initParticles();
            particlesInitialized.current = true;
            console.log("Particles initialized successfully");
          } catch (error) {
            console.error("Error initializing particles:", error);
            logError(error);
          }
        }
      }
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const particlesElement = document.getElementById('particles-js');
          if (particlesElement) {
            initializeParticles();
            observer.disconnect();
          }
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const valid = await isSessionValid();
        if (valid) {
          login();
        } else {
          logout();
          // Only redirect to login if trying to access a protected route
          const protectedRoutes = ['/dashboard', '/asset-manager', '/profile', '/create-collection', '/historic-metal-prices'];
          if (protectedRoutes.includes(location.pathname)) {
            navigate('/login');
          }
        }
      } catch (error) {
        console.error('Error checking login status:', error);
        logError(error);
        setGlobalError('Unable to verify login status. Please try again later.');
      }
    };

    checkLoginStatus();
  }, [login, logout, navigate, location]);

  useEffect(() => {
    if (location.pathname === '/') {
      const urlParams = new URLSearchParams(window.location.search);
      const section = urlParams.get('section');
      if (section) {
        const element = document.getElementById(section);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (location.state?.scrollToSignup) {
        const signupElement = document.getElementById('signup');
        if (signupElement) {
          signupElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
      // Clear the state and URL parameters
      window.history.replaceState({}, document.title, '/');
    }
  }, [location]);

  const openBetaToS = () => setShowBetaToS(true);
  const closeBetaToS = () => setShowBetaToS(false);
  const openAboutUs = () => setShowAboutUs(true);
  const closeAboutUs = () => setShowAboutUs(false);
  const openContribute = () => setShowContribute(true);
  const closeContribute = () => setShowContribute(false);
  const openPrivacyPolicy = () => setShowPrivacyPolicy(true);
  const closePrivacyPolicy = () => setShowPrivacyPolicy(false);
  const openTermsOfService = () => setShowTermsOfService(true);
  const closeTermsOfService = () => setShowTermsOfService(false);
  const openSupport = () => setShowSupport(true);
  const closeSupport = () => setShowSupport(false);

  const handleSignOut = async () => {
    try {
      await signOut();
      logout();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
      logError(error);
      setGlobalError('Unable to sign out. Please try again later.');
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const renderNavItems = (isMobile = false) => {
    const closeMenuIfMobile = isMobile ? toggleMobileMenu : () => {};
    const isLoginPage = location.pathname === '/login';

    if (isAuthenticated) {
      return (
        <>
          <li><Link to="/dashboard" onClick={closeMenuIfMobile}>Dashboard</Link></li>
          <li><Link to="/asset-manager" onClick={closeMenuIfMobile}>Asset Manager</Link></li>
          <li><Link to="/collections" onClick={closeMenuIfMobile}>Collections</Link></li>
          <li><Link to="/historic-metal-prices" onClick={closeMenuIfMobile}>Metal Prices</Link></li>
          <li><Link to="/profile" onClick={closeMenuIfMobile}>Profile</Link></li>
          <li><Link to="/" onClick={() => { handleSignOut(); closeMenuIfMobile(); }}>Logout</Link></li>
        </>
      );
    } else {
      return (
        <>
          <li><Link to="/" onClick={closeMenuIfMobile}>Home</Link></li>
          <li>
            {isLoginPage ? (
              <Link to="/?section=features" onClick={closeMenuIfMobile}>Features</Link>
            ) : (
              <a href="#features" onClick={closeMenuIfMobile}>Features</a>
            )}
          </li>
          <li>
            {isLoginPage ? (
              <Link to="/?section=benefits" onClick={closeMenuIfMobile}>Benefits</Link>
            ) : (
              <a href="#benefits" onClick={closeMenuIfMobile}>Benefits</a>
            )}
          </li>
          <li>
            {isLoginPage ? (
              <Link to="/?section=testimonials" onClick={closeMenuIfMobile}>Testimonials</Link>
            ) : (
              <a href="#testimonials" onClick={closeMenuIfMobile}>Testimonials</a>
            )}
          </li>
          <li>
            {isLoginPage ? (
              <Link to="/?section=roadmap" onClick={closeMenuIfMobile}>Roadmap</Link>
            ) : (
              <a href="#roadmap" onClick={closeMenuIfMobile}>Roadmap</a>
            )}
          </li>
          <li>
            {isLoginPage ? (
              <Link to="/?section=signup" onClick={closeMenuIfMobile}>Join Beta</Link>
            ) : (
              <a href="#signup" onClick={closeMenuIfMobile}>Join Beta</a>
            )}
          </li>
          <li><Link to="/login" onClick={closeMenuIfMobile}>Login</Link></li>
        </>
      );
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (globalError) {
    return (
      <div className="error-container">
        <h1>Oops! Something went wrong.</h1>
        <p>{globalError}</p>
        <button onClick={() => window.location.reload()}>Refresh Page</button>
      </div>
    );
  }

  return (
    <div className="App">
      <div id="particles-js"></div>
      <div className="navigation-wrapper">
        <nav className="main-nav">
          <div className="container">
            <ul className="desktop-menu">
              {renderNavItems()}
            </ul>
          </div>
        </nav>
        <div className={`hamburger-icon ${mobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
          <ul>
            {renderNavItems(true)}
          </ul>
        </div>
      </div>

      <ErrorBoundary>
        <Routes>
          <Route path="/" element={
            <>
              <HeroHeader />
              <Features />
              <Benefits />
              <Testimonials />
              <Roadmap />
              <Signup openBetaToS={openBetaToS} />
            </>
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<UserSignup />} />
          <Route path="/api-services" element={<ApiServices />} />
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path="/asset-manager" element={
            <ProtectedRoute>
              <AssetManager />
            </ProtectedRoute>
          } />
          <Route path="/profile" element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          } />
          <Route path="/auth-callback" element={<AuthCallback />} />
          <Route path="/create-collection" element={
            <ProtectedRoute>
              <CreateCollection />
            </ProtectedRoute>
          } />
          <Route path="/link-accounts" element={
            <ProtectedRoute>
              <LinkAccounts />
            </ProtectedRoute>
          } />
          <Route path="/collections" element={
            <ProtectedRoute>
              <CollectionList />
            </ProtectedRoute>
          } />
          <Route path="/collections/:id" element={
            <ProtectedRoute>
              <CollectionDetail />
            </ProtectedRoute>
          } />
          <Route path="/historic-metal-prices" element={
            <ProtectedRoute>
              <HistoricMetalPrices />
            </ProtectedRoute>
          } />
          <Route path="/add-asset-to-collection/:collectionId" element={
            <ProtectedRoute>
              <AddAssetToCollection />
            </ProtectedRoute>
          } />
          <Route path="/asset-details/:assetId" element={
            <ProtectedRoute>
              <AssetDetails />
            </ProtectedRoute>
          } />
          <Route path="/quick-add-asset" element={
            <ProtectedRoute>
              <QuickAddAsset />
            </ProtectedRoute>
          } />
          <Route path="/bulk-edit-assets" element={
            <ProtectedRoute>
              <BulkEditAssets />
            </ProtectedRoute>
          } />
          <Route path="/collection-organizer" element={
            <ProtectedRoute>
              <CollectionOrganizer />
            </ProtectedRoute>
          } />
          <Route path="/collection-grid" element={
            <ProtectedRoute>
              <CollectionGrid />
            </ProtectedRoute>
          } />
          <Route path="/share-collection/:collectionId" element={
            <ProtectedRoute>
              <ShareCollection />
            </ProtectedRoute>
          } />
          <Route path="/auto-suggest-search" element={
            <ProtectedRoute>
              <AutoSuggestSearch />
            </ProtectedRoute>
          } />
          <Route path="/advanced-search" element={
            <ProtectedRoute>
              <AdvancedSearch />
            </ProtectedRoute>
          } />
          <Route path="/visual-search" element={
            <ProtectedRoute>
              <VisualSearch />
            </ProtectedRoute>
          } />
          <Route path="/asset-category-browser" element={
            <ProtectedRoute>
              <AssetCategoryBrowser />
            </ProtectedRoute>
          } />
          <Route path="/asset-recommendations" element={
            <ProtectedRoute>
              <AssetRecommendations />
            </ProtectedRoute>
          } />
          <Route path="/add-to-my-collections/:assetId" element={
            <ProtectedRoute>
              <AddToMyCollections />
            </ProtectedRoute>
          } />
          <Route path="/price-ticker" element={<PriceTicker />} />
        </Routes>
      </ErrorBoundary>

      <Footer 
        openAboutUs={openAboutUs} 
        openContribute={openContribute} 
        openPrivacyPolicy={openPrivacyPolicy}
        openTermsOfService={openTermsOfService}
        openSupport={openSupport}
      />
      <BetaToS isOpen={showBetaToS} onClose={closeBetaToS} />
      <AboutUs isOpen={showAboutUs} onClose={closeAboutUs} />
      <Contribute isOpen={showContribute} onClose={closeContribute} />
      <PrivacyPolicy isOpen={showPrivacyPolicy} onClose={closePrivacyPolicy} />
      <TermsOfService isOpen={showTermsOfService} onClose={closeTermsOfService} />
      <Support isOpen={showSupport} onClose={closeSupport} />
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;
