import React, { useState, useEffect } from 'react';
import { createClient } from '../supabaseClient';

const QuickStats = () => {
  const [stats, setStats] = useState({
    totalAssets: 0,
    change24h: 0,
    change7d: 0,
    change30d: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      setLoading(true);
      setError(null);
      const supabase = createClient();

      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('User not authenticated');

        // Fetch user's collections
        const { data: collections, error: collectionsError } = await supabase
          .from('user_collections')
          .select('id, total_value')
          .eq('user_id', user.id);

        if (collectionsError) throw collectionsError;

        // Calculate total assets value
        const totalAssets = collections.reduce((sum, collection) => sum + collection.total_value, 0);

        // Fetch historical data for percentage changes
        // This is a placeholder. In a real application, you would need to implement
        // a way to track historical values of collections.
        const change24h = 0.5;  // Placeholder value
        const change7d = 1.2;   // Placeholder value
        const change30d = -0.8; // Placeholder value

        setStats({
          totalAssets,
          change24h,
          change7d,
          change30d,
        });
      } catch (err) {
        console.error('Error fetching quick stats:', err);
        setError('Failed to fetch stats. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) return <div>Loading stats...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="quick-stats">
      <h2>Quick Stats</h2>
      <ul>
        <li>Total Assets: ${stats.totalAssets.toFixed(2)}</li>
        <li>24h Change: {stats.change24h.toFixed(2)}%</li>
        <li>7d Change: {stats.change7d.toFixed(2)}%</li>
        <li>30d Change: {stats.change30d.toFixed(2)}%</li>
      </ul>
    </div>
  );
};

export default QuickStats;