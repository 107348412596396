import React from 'react';

const NewsFeed = ({ newsItems }) => {
  return (
    <div className="news-feed">
      <h2>Latest News</h2>
      {newsItems.length > 0 ? (
        <ul>
          {newsItems.map((item) => (
            <li key={item.id}>
              <h3>{item.title}</h3>
              <p>{item.date}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No news items to display.</p>
      )}
    </div>
  );
};

export default NewsFeed;