import React, { useState } from 'react';
import { Form, Input, InputNumber, Select, Button, message } from 'antd';
import { createClient } from '../supabaseClient';
import './QuickAddAsset.css';

const { Option } = Select;

const QuickAddAsset = ({ onAssetAdded }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const supabase = createClient();
      const { error } = await supabase
        .from('public_assets')
        .insert([
          {
            name: values.name,
            year: values.year,
            metal: values.metal,
            weight: values.weight,
            quantity: values.quantity,
            totalValue: values.weight * values.quantity * (values.metal === 'Gold' ? 1800 : values.metal === 'Silver' ? 25 : values.metal === 'Platinum' ? 1000 : 2000) // Simplified price calculation
          }
        ]);

      if (error) throw error;

      message.success('Asset added successfully');
      form.resetFields();
      if (onAssetAdded) onAssetAdded();
    } catch (error) {
      console.error('Error adding asset:', error);
      message.error('Failed to add asset');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="quick-add-asset">
      <h2>Quick Add Asset</h2>
      <Form
        form={form}
        name="quickAddAsset"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="Asset Name"
          rules={[{ required: true, message: 'Please input the asset name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="year"
          label="Year"
          rules={[{ required: true, message: 'Please input the year!' }]}
        >
          <InputNumber style={{ width: '100%' }} min={1800} max={new Date().getFullYear()} />
        </Form.Item>

        <Form.Item
          name="metal"
          label="Metal"
          rules={[{ required: true, message: 'Please select the metal!' }]}
        >
          <Select>
            <Option value="Gold">Gold</Option>
            <Option value="Silver">Silver</Option>
            <Option value="Platinum">Platinum</Option>
            <Option value="Palladium">Palladium</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="weight"
          label="Weight (oz)"
          rules={[{ required: true, message: 'Please input the weight!' }]}
        >
          <InputNumber style={{ width: '100%' }} min={0.1} step={0.1} />
        </Form.Item>

        <Form.Item
          name="quantity"
          label="Quantity"
          rules={[{ required: true, message: 'Please input the quantity!' }]}
        >
          <InputNumber style={{ width: '100%' }} min={1} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} className="submit-button">
            Add Asset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default QuickAddAsset;