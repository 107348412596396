import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="privacy-policy-modal">
      <div className="privacy-policy-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <div className="privacy-policy-container">
          <section className="hero-section">
            <h1>Privacy Policy for MetalStackers.io</h1>
            <h2>Effective Date: [Insert Date]</h2>
          </section>

          <section className="privacy-section">
            <p>Welcome to MetalStackers.io! Your privacy is important to us, and this Privacy Policy explains how we collect, use, share, and protect your information when you visit our website or use our services. By using MetalStackers.io, you agree to the practices described in this Privacy Policy.</p>
          
            <h2>1. Information We Collect</h2>
            <p>We collect the following types of information:</p>
            <ul>
              <li><strong>Personal Information:</strong> This includes information you provide to us directly, such as your name, email address, and any other contact information you submit when signing up for our newsletter, becoming a beta tester, or contacting us.</li>
              <li><strong>Usage Data:</strong> We collect information about your interaction with our website, such as your IP address, browser type, operating system, referral URLs, pages visited, links clicked, and other actions you take on our site.</li>
              <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to collect information about your browsing behavior and preferences. This helps us improve our website and provide a better user experience.</li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <p>We use the information we collect for various purposes, including:</p>
            <ul>
              <li><strong>To Provide and Improve Our Services:</strong> We use your information to operate, maintain, and improve MetalStackers.io, including developing new features and enhancing user experience.</li>
              <li><strong>To Communicate with You:</strong> We use your email address to send you newsletters, updates, promotional materials, and other information related to MetalStackers.io. You can opt out of these communications at any time by following the unsubscribe instructions provided in our emails.</li>
              <li><strong>To Process Transactions:</strong> If you make purchases or engage with affiliate links on our site, we may use your information to process payments, track orders, and provide customer support.</li>
              <li><strong>To Analyze Usage:</strong> We use analytics tools to understand how users interact with our website, which helps us optimize our content and improve our marketing strategies.</li>
              <li><strong>To Ensure Security and Compliance:</strong> We use your information to protect the security and integrity of our website and comply with legal obligations.</li>
            </ul>

            <h2>3. Affiliate Marketing</h2>
            <p>MetalStackers.io participates in affiliate marketing programs, which means we may earn commissions on purchases made through links to affiliate partners, such as APMEX and SD Bullion. These affiliate links do not affect the price you pay and are used to support our platform. We do not share your personal information with our affiliate partners unless explicitly stated.</p>

            <h2>4. Data Sharing and Disclosure</h2>
            <p>We do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:</p>
            <ul>
              <li><strong>With Service Providers:</strong> We may share your information with third-party service providers who assist us with website operations, analytics, marketing, payment processing, and customer support. These service providers are required to protect your information and use it only for the purposes specified by us.</li>
              <li><strong>For Legal Reasons:</strong> We may disclose your information if required by law, court order, or governmental request, or if we believe it is necessary to protect the rights, property, or safety of MetalStackers.io, our users, or others.</li>
              <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owners or partners as part of the business transfer.</li>
            </ul>

            <h2>5. Data Storage and Security</h2>
            <p>We store your data in a secure database and take appropriate measures to protect it from unauthorized access, alteration, disclosure, or destruction. While we strive to protect your information, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.</p>

            <h2>6. Your Rights and Choices</h2>
            <p>You have the right to:</p>
            <ul>
              <li><strong>Access and Update Your Information:</strong> You can access and update your personal information by contacting us at contact@metalstackers.io.</li>
              <li><strong>Opt-Out of Communications:</strong> You can opt-out of receiving promotional emails from us by following the unsubscribe instructions in the emails or by contacting us directly.</li>
              <li><strong>Disable Cookies:</strong> Most web browsers allow you to manage cookies through their settings. You can choose to accept, decline, or delete cookies. Please note that disabling cookies may affect the functionality of our website.</li>
            </ul>

            <h2>7. Third-Party Websites</h2>
            <p>Our website may contain links to third-party websites, including affiliate partners. We are not responsible for the privacy practices or content of these third-party websites. We encourage you to read the privacy policies of any websites you visit.</p>

            <h2>8. Children's Privacy</h2>
            <p>MetalStackers.io is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe a child has provided us with personal information, please contact us immediately, and we will take steps to delete the information.</p>

            <h2>9. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will notify you of any significant changes by posting the updated policy on our website and updating the effective date. Your continued use of MetalStackers.io after any changes constitutes your acceptance of the revised policy.</p>

            <h2>10. Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at contact@metalstackers.io.</p>

            <p>Thank you for being a part of the MetalStackers.io community! Your privacy and trust are important to us, and we are committed to protecting your information.</p>
          </section>

          <div className="bottom-close-button-container">
            <button className="bottom-close-button" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;