import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Input, Button, Space, message, Modal, Form, Card, Select } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { createClient } from '../supabaseClient';
import './CollectionOrganizer.css';

const CollectionOrganizer = () => {
  const { collectionId } = useParams();
  const [coins, setCoins] = useState([]);
  const [availableCoins, setAvailableCoins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const fetchCoins = useCallback(async () => {
    setLoading(true);
    const supabase = createClient();
    const { data, error } = await supabase
      .from('user_collections')
      .select('*, public_assets(*)')
      .eq('id', collectionId)
      .single();

    if (error) {
      console.error('Error fetching collection:', error);
      message.error('Failed to fetch collection');
    } else if (data) {
      setCoins(data.public_assets || []);
    }
    setLoading(false);
  }, [collectionId]);

  const fetchAvailableCoins = useCallback(async () => {
    const supabase = createClient();
    const { data, error } = await supabase
      .from('public_assets')
      .select('*')
      .not('id', 'in', `(${coins.map(c => c.id).join(',')})`)
      .eq('asset_type', 'Non-circulating coin');

    if (error) {
      console.error('Error fetching available coins:', error);
      message.error('Failed to fetch available coins');
    } else {
      setAvailableCoins(data);
    }
  }, [coins]);

  useEffect(() => {
    fetchCoins();
    fetchAvailableCoins();
  }, [fetchCoins, fetchAvailableCoins]);

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => document.querySelector('.ant-table-filter-dropdown input')?.select());
      }
    },
    render: (text) =>
      searchText ? (
        <span style={{ fontWeight: 'bold' }}>{text}</span>
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      sorter: (a, b) => a.year - b.year,
    },
    {
      title: 'Metal',
      dataIndex: 'metal',
      key: 'metal',
      filters: [
        { text: 'Gold', value: 'Gold' },
        { text: 'Silver', value: 'Silver' },
        { text: 'Platinum', value: 'Platinum' },
        { text: 'Palladium', value: 'Palladium' },
      ],
      onFilter: (value, record) => record.metal === value,
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
      sorter: (a, b) => a.weight - b.weight,
      render: (text, record) => `${text} ${record.weight_unit}`,
    },
    {
      title: 'Current Value',
      dataIndex: 'current_value',
      key: 'current_value',
      sorter: (a, b) => a.current_value - b.current_value,
      render: (text) => `$${text.toFixed(2)}`,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button onClick={() => handleRemoveCoin(record.id)} type="link" danger>
          Remove
        </Button>
      ),
    },
  ];

  const handleRemoveCoin = async (coinId) => {
    const supabase = createClient();
    const { data, error } = await supabase
      .from('user_collections')
      .select('public_assets')
      .eq('id', collectionId)
      .single();

    if (error) {
      console.error('Error fetching collection:', error);
      message.error('Failed to remove coin from collection');
      return;
    }

    const updatedAssets = data.public_assets.filter(asset => asset.id !== coinId);

    const { error: updateError } = await supabase
      .from('user_collections')
      .update({ public_assets: updatedAssets })
      .eq('id', collectionId);

    if (updateError) {
      console.error('Error removing coin from collection:', updateError);
      message.error('Failed to remove coin from collection');
    } else {
      message.success('Coin removed from collection successfully');
      fetchCoins();
      fetchAvailableCoins();
    }
  };

  const handleAddCoin = async (values) => {
    const supabase = createClient();
    const { data, error } = await supabase
      .from('user_collections')
      .select('public_assets')
      .eq('id', collectionId)
      .single();

    if (error) {
      console.error('Error fetching collection:', error);
      message.error('Failed to add coin to collection');
      return;
    }

    const newCoin = availableCoins.find(coin => coin.id === values.coinId);
    const updatedAssets = [...data.public_assets, newCoin];

    const { error: updateError } = await supabase
      .from('user_collections')
      .update({ public_assets: updatedAssets })
      .eq('id', collectionId);

    if (updateError) {
      console.error('Error adding coin to collection:', updateError);
      message.error('Failed to add coin to collection');
    } else {
      message.success('Coin added to collection successfully');
      fetchCoins();
      fetchAvailableCoins();
      setIsModalVisible(false);
      form.resetFields();
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;

    if (result.source.droppableId === 'availableCoins' && result.destination.droppableId === 'collectionCoins') {
      const coin = availableCoins[sourceIndex];
      await handleAddCoin({ coinId: coin.id });
    }
  };

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    form.resetFields();
  }, [form]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="collection-organizer">
        <h2>Organize Collection</h2>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={showModal}
          style={{ marginBottom: 16 }}
        >
          Add Coin to Collection
        </Button>
        <div style={{ display: 'flex' }}>
          <Droppable droppableId="collectionCoins">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ flex: 2, marginRight: 16 }}
              >
                <Table columns={columns} dataSource={coins} loading={loading} rowKey="id" />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="availableCoins">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ flex: 1 }}
              >
                <h3>Available Coins</h3>
                {availableCoins.map((coin, index) => (
                  <Draggable key={coin.id} draggableId={coin.id.toString()} index={index}>
                    {(provided) => (
                      <Card
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{ marginBottom: 8 }}
                      >
                        <p>{coin.name}</p>
                        <p>{coin.year} - {coin.metal}</p>
                      </Card>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <Modal
          title="Add Coin to Collection"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form form={form} onFinish={handleAddCoin} layout="vertical">
            <Form.Item
              name="coinId"
              label="Coin"
              rules={[{ required: true, message: 'Please select a coin' }]}
            >
              <Select>
                {availableCoins.map(coin => (
                  <Select.Option key={coin.id} value={coin.id}>
                    {coin.name} ({coin.year} - {coin.metal})
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Add Coin
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </DragDropContext>
  );
};

export default CollectionOrganizer;