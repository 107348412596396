import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createBrowserSupabaseClient, signInWithEmail } from '../supabaseClient';

function LinkAccounts() {
  const location = useLocation();
  const navigate = useNavigate();
  const { newUser, existingUser } = location.state;

  const handleLink = async () => {
    // Link the accounts here
    // This might involve updating the existing user's auth providers
    // or merging user data
    
    const supabase = createBrowserSupabaseClient();
    // For example:
    const { error } = await supabase
      .from('users')
      .update({ github_id: newUser.id })
      .eq('id', existingUser.id);

    if (error) {
      console.error('Error linking accounts:', error);
    } else {
      // Successfully linked, log in as existing user
      await signInWithEmail(existingUser.email, ''); // Note: You'll need to handle password input separately
      navigate('/dashboard');
    }
  };

  const handleCreateNew = async () => {
    // Proceed with the new GitHub account
    const supabase = createBrowserSupabaseClient();
    const { data } = await supabase.auth.getSession();
    if (data?.session) {
      await supabase.auth.setSession(data.session);
      navigate('/dashboard');
    }
  };

  return (
    <div>
      <h2>Link Accounts</h2>
      <p>We found an existing account with the email {existingUser.email}.</p>
      <p>Would you like to link your GitHub account to this existing account?</p>
      <button onClick={handleLink}>Link Accounts</button>
      <button onClick={handleCreateNew}>Create New Account</button>
    </div>
  );
}

export default LinkAccounts;