import React from 'react';

function Benefits() {
  return (
    <section id="benefits" className="benefits section">
      <div className="container">
        <h2 className="glow-text">Why Join Our Beta?</h2>
        <ul>
          <li>Be the first to experience cutting-edge features.</li>
          <li>Gain exclusive early access to new tools and updates.</li>
          <li>Provide feedback that helps shape the future of MetalStackers.</li>
          <li>Earn special rewards and recognition as a beta tester.</li>
        </ul>
        <div className="callout neon-border">
          <p>Beta testers receive a special launch offer! Sign up now to secure your spot.</p>
        </div>
        <button className="cta-button primary" onClick={() => document.getElementById('signup').scrollIntoView({ behavior: 'smooth' })}>
          Become a Beta Tester
        </button>
      </div>
    </section>
  );
}

export default Benefits;