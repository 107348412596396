import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'antd';
import { createClient } from '../supabaseClient';
import './AutoSuggestSearch.css';

const AutoSuggestSearch = ({ placeholder, onSelect, style }) => {
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (searchText.length > 1) {
      fetchSuggestions(searchText);
    } else {
      setOptions([]);
    }
  }, [searchText]);

  const fetchSuggestions = async (query) => {
    const supabase = createClient();
    const { data, error } = await supabase
      .from('public_assets')
      .select('issuer, metal, year, denomination')
      .or(`issuer.ilike.%${query}%,metal.ilike.%${query}%,year.ilike.%${query}%,denomination.ilike.%${query}%`)
      .limit(10);

    if (error) {
      console.error('Error fetching suggestions:', error);
    } else {
      const formattedOptions = data.map(item => ({
        value: `${item.issuer} ${item.year} ${item.denomination}`,
        label: (
          <div className="asset-suggestion-item">
            <span className="asset-suggestion-name">{item.issuer} {item.year} {item.denomination}</span>
            <span className="asset-suggestion-details">{item.metal}</span>
          </div>
        )
      }));
      setOptions(formattedOptions);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleSelect = (value, option) => {
    onSelect(value, option);
  };

  return (
    <div className="auto-suggest-search">
      <AutoComplete
        className="auto-suggest-input"
        options={options}
        onSelect={handleSelect}
        onSearch={handleSearch}
        placeholder={placeholder}
        style={style}
        dropdownClassName="suggestions-list"
      />
    </div>
  );
};

export default AutoSuggestSearch;