import React from 'react';
import './BetaToS.css';

const BetaToS = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="beta-tos-overlay">
      <div className="beta-tos-modal">
        <button className="close-button" onClick={onClose}>&times;</button>
        <div className="beta-tos-content">
          <h1>MetalStackers Beta Program Terms of Service</h1>
          <div className="section">
            <p><strong>Effective Date: [Insert Date]</strong></p>
            <p>Welcome to the MetalStackers Beta Program! By signing up to participate in our beta testing, you agree to the following terms and conditions. Please read them carefully before proceeding.</p>
          </div>

          <div className="section">
            <h2>1. Beta Program Overview</h2>
            <p>The MetalStackers Beta Program is designed to allow a limited number of users to test our new application before its public release. Your participation in the beta program is voluntary, and you may discontinue your participation at any time.</p>
          </div>

          <div className="section">
            <h2>2. Eligibility</h2>
            <p>To participate in the MetalStackers Beta Program, you must be at least 18 years of age. By signing up, you represent and warrant that you meet this eligibility requirement.</p>
          </div>

          <div className="section">
            <h2>3. User Feedback</h2>
            <p>As a beta tester, you agree to provide feedback on your experience using the MetalStackers app. Your feedback is crucial for improving our product, and we may contact you via email or other means to ask for your input.</p>
          </div>

          <div className="section">
            <h2>4. Beta Testing Guidelines</h2>
            <p><strong>Confidentiality:</strong> The MetalStackers Beta Program is confidential. By participating, you agree not to share, distribute, or disclose any details about the beta application, including screenshots, features, or any information provided to you as part of the program, to anyone outside of the beta testing group.</p>
            <p><strong>Use of the Beta Version:</strong> The beta version of the MetalStackers app may contain bugs, errors, or other issues that could result in data loss. You acknowledge and agree that your use of the beta version is at your own risk, and MetalStackers is not liable for any issues that may arise.</p>
          </div>

          <div className="section">
            <h2>5. Affiliate Offers</h2>
            <p>By signing up for the MetalStackers Beta Program, you agree to receive emails that may include affiliate offers. These offers may include deals on gold, silver, and other precious metal products from our partners, such as APMEX.</p>
            <p>We take great care to ensure that any affiliate offers we send are valuable and relevant to our users. We strive to provide deals that we believe will benefit our community and do not intend to overwhelm you with frequent or irrelevant promotions. Your trust is important to us, and we will never share your email address or personal information with third parties without your explicit consent.</p>
          </div>

          <div className="section">
            <h2>6. Opting Out</h2>
            <p>If you wish to stop receiving affiliate offers or opt out of the MetalStackers Beta Program at any time, you may do so by following the unsubscribe instructions included in our emails or by contacting us directly at [Insert Contact Email].</p>
          </div>

          <div className="section">
            <h2>7. Privacy</h2>
            <p>Your privacy is important to us. By signing up for the MetalStackers Beta Program, you agree to our Privacy Policy and acknowledge that your data will be processed in accordance with it. We will not share your personal information with third parties except as necessary to provide the beta services or as required by law.</p>
          </div>

          <div className="section">
            <h2>8. Modification of Terms</h2>
            <p>MetalStackers reserves the right to modify these terms at any time. We will notify you of any significant changes via email or through the MetalStackers app. Your continued participation in the beta program after any such changes constitutes your acceptance of the new terms.</p>
          </div>

          <div className="section">
            <h2>9. Governing Law</h2>
            <p>These terms are governed by and construed in accordance with the laws of [Insert Jurisdiction]. Any disputes arising from or related to these terms will be subject to the exclusive jurisdiction of the courts of [Insert Jurisdiction].</p>
          </div>

          <div className="section">
            <h2>10. Contact Us</h2>
            <p>If you have any questions or concerns about these Terms of Service, please contact us at [Insert Contact Email].</p>
          </div>

          <div className="section">
            <p>By signing up for the MetalStackers Beta Program, you acknowledge that you have read, understood, and agreed to these Terms of Service. Thank you for helping us build a better MetalStackers experience!</p>
          </div>
          <button onClick={onClose} className="cta-button">Close</button>
        </div>
      </div>
    </div>
  );
};

export default BetaToS;