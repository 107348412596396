import React, { useState } from 'react';
import { Upload, message, Card, Col, Row } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { createClient } from '../supabaseClient';
import './VisualSearch.css';

const { Dragger } = Upload;
const { Meta } = Card;

const VisualSearch = () => {
  const [searchResults, setSearchResults] = useState([]);

  const props = {
    name: 'file',
    multiple: false,
    action: 'https://your-image-analysis-api-endpoint.com/upload', // Replace with your actual image analysis API endpoint
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        performVisualSearch(info.file.response);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const performVisualSearch = async (analysisResult) => {
    const supabase = createClient();
    // Here, you would use the analysis result to search for similar coins in your database
    // This is a placeholder implementation
    const { data, error } = await supabase
      .from('coins')
      .select('*')
      .limit(10); // Limit to 10 results for this example

    if (error) {
      console.error('Error performing visual search:', error);
      message.error('Failed to perform visual search');
    } else {
      setSearchResults(data);
    }
  };

  return (
    <div className="visual-search">
      <h2>Visual Search</h2>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other
          sensitive files.
        </p>
      </Dragger>

      {searchResults.length > 0 && (
        <div className="search-results">
          <h3>Similar Coins</h3>
          <Row gutter={[16, 16]}>
            {searchResults.map((coin) => (
              <Col xs={24} sm={12} md={8} lg={6} key={coin.id}>
                <Card
                  hoverable
                  cover={<img alt={coin.name} src={coin.image_url || 'https://via.placeholder.com/150'} />}
                >
                  <Meta title={coin.name} description={`${coin.year} ${coin.metal} - ${coin.weight}oz`} />
                  <p>Price: ${coin.price}</p>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default VisualSearch;