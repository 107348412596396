import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { createBrowserSupabaseClient } from '../supabaseClient';
import './AddAssetToCollection.css';

function AddAssetToCollection() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [collection, setCollection] = useState(null);
  const [newAsset, setNewAsset] = useState({
    year: '',
    denomination: '',
    metal: '',
    weight: '',
    purity: '',
    mintage: '',
    mint_mark: '',
    is_proof: false,
    design_description: '',
    image_obverse: '',
    image_reverse: '',
    current_value: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isOwner, setIsOwner] = useState(false);

  const fetchCollectionDetails = useCallback(async () => {
    const supabase = createBrowserSupabaseClient();
    const { data: { user } } = await supabase.auth.getUser();

    // First, try to fetch from user_collections
    let { data, error } = await supabase
      .from('user_collections')
      .select('*')
      .eq('id', id)
      .single();

    // If not found in user_collections, try public_collections
    if (error && error.code === 'PGRST116') {
      ({ data, error } = await supabase
        .from('public_collections')
        .select('*')
        .eq('id', id)
        .single());
    }

    if (error) {
      setError('Error fetching collection details');
    } else {
      setCollection(data);
      setIsOwner(user && data.user_id === user.id);
    }
  }, [id]);

  useEffect(() => {
    fetchCollectionDetails();
  }, [fetchCollectionDetails]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewAsset(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isOwner) {
      setError('You do not have permission to add assets to this collection.');
      return;
    }
    setLoading(true);
    setError('');

    const supabase = createBrowserSupabaseClient();
    const { error: assetError } = await supabase
      .from('public_assets')
      .insert([
        {
          ...newAsset,
          collection_id: id
        }
      ])
      .select()
      .single();

    if (assetError) {
      setError('Error adding asset to collection');
      setLoading(false);
      return;
    }

    // Update collection statistics
    const newTotalAssets = (collection.total_assets || 0) + 1;
    const newTotalValue = (collection.total_value || 0) + Number(newAsset.current_value);
    const newCompleteness = (newTotalAssets / (collection.total_assets || 1)) * 100;

    const { error: updateError } = await supabase
      .from(collection.is_public ? 'public_collections' : 'user_collections')
      .update({
        total_assets: newTotalAssets,
        total_value: newTotalValue,
        completeness: newCompleteness
      })
      .eq('id', id);

    if (updateError) {
      setError('Error updating collection statistics');
      setLoading(false);
    } else {
      navigate(`/collections/${id}`, { state: { newAssetAdded: true } });
    }
  };

  if (!collection) return <div>Loading...</div>;
  if (!isOwner) return <div>You do not have permission to add assets to this collection.</div>;

  return (
    <div className="add-asset-to-collection">
      <h2>Add Asset to {collection.name}</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="number"
          name="year"
          value={newAsset.year}
          onChange={handleInputChange}
          placeholder="Year"
          required
        />
        <input
          type="text"
          name="denomination"
          value={newAsset.denomination}
          onChange={handleInputChange}
          placeholder="Denomination"
          required
        />
        <input
          type="text"
          name="metal"
          value={newAsset.metal}
          onChange={handleInputChange}
          placeholder="Metal"
          required
        />
        <input
          type="number"
          name="weight"
          value={newAsset.weight}
          onChange={handleInputChange}
          placeholder="Weight (g)"
          step="0.01"
          required
        />
        <input
          type="number"
          name="purity"
          value={newAsset.purity}
          onChange={handleInputChange}
          placeholder="Purity (%)"
          step="0.01"
          required
        />
        <input
          type="number"
          name="mintage"
          value={newAsset.mintage}
          onChange={handleInputChange}
          placeholder="Mintage"
        />
        <input
          type="text"
          name="mint_mark"
          value={newAsset.mint_mark}
          onChange={handleInputChange}
          placeholder="Mint Mark"
        />
        <label>
          <input
            type="checkbox"
            name="is_proof"
            checked={newAsset.is_proof}
            onChange={handleInputChange}
          />
          Proof Asset
        </label>
        <textarea
          name="design_description"
          value={newAsset.design_description}
          onChange={handleInputChange}
          placeholder="Design Description"
        />
        <input
          type="text"
          name="image_obverse"
          value={newAsset.image_obverse}
          onChange={handleInputChange}
          placeholder="Obverse Image URL"
        />
        <input
          type="text"
          name="image_reverse"
          value={newAsset.image_reverse}
          onChange={handleInputChange}
          placeholder="Reverse Image URL"
        />
        <input
          type="number"
          name="current_value"
          value={newAsset.current_value}
          onChange={handleInputChange}
          placeholder="Current Value"
          step="0.01"
          required
        />
        {error && <p className="error">{error}</p>}
        <div className="button-group">
          <button type="submit" disabled={loading}>
            {loading ? 'Adding...' : 'Add Asset to Collection'}
          </button>
          <Link to={`/collections/${id}`} className="back-button">Back to Collection</Link>
        </div>
      </form>
    </div>
  );
}

export default AddAssetToCollection;