import React from 'react';
import './Support.css';

const Support = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="support-modal">
      <div className="support-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <div className="support-container">
          <section className="hero-section">
            <h1>Support Page for MetalStackers.io</h1>
            <h2>We're Here to Help!</h2>
          </section>

          <section className="support-info">
            <h2>How to Get Support</h2>
            <div className="two-column-layout">
              <div className="text-column">
                <p>At MetalStackers.io, we are dedicated to providing you with the best possible experience. If you have any questions, need assistance, or encounter any issues while using our website, please don't hesitate to reach out to us.</p>
                <p>For any support inquiries, please email us at:</p>
                <p><strong>support@metalstackers.io</strong></p>
                <p>Our friendly support team is here to assist you and will respond to your inquiry as soon as possible.</p>
              </div>
              <div className="image-column">
                <i className="fas fa-headset fa-5x"></i>
              </div>
            </div>
          </section>

          <section className="faq">
            <h2>Common Questions</h2>
            <p>Before contacting support, you might find an answer in our FAQ section, where we address common questions about MetalStackers.io, account management, and using our services.</p>
            {/* Add FAQ items here */}
          </section>

          <section className="stay-connected">
            <h2>Stay Connected</h2>
            <p>Follow us on social media for the latest updates and announcements:</p>
            <div className="social-links">
              <div className="social-item">
                <i className="fab fa-twitter fa-3x"></i>
                <p>Twitter</p>
              </div>
              <div className="social-item">
                <i className="fab fa-facebook fa-3x"></i>
                <p>Facebook</p>
              </div>
              <div className="social-item">
                <i className="fab fa-instagram fa-3x"></i>
                <p>Instagram</p>
              </div>
            </div>
          </section>

          <section className="closing-message">
            <h2>Thank You for Choosing MetalStackers.io</h2>
            <p>We appreciate your support and are always here to help you with your metal stacking journey!</p>
            <h3>MetalStackers.io</h3>
            <p><em>Empowering You to Invest in Precious Metals with Confidence.</em></p>
          </section>

          <section className="additional-info">
            <h2>Get Quick Assistance</h2>
            <p>For immediate assistance, please include as much detail as possible in your email, such as your account information, a description of the issue, and any relevant screenshots. This will help us resolve your issue more quickly.</p>
          </section>

          <div className="bottom-close-button-container">
            <button className="bottom-close-button" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;