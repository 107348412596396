import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { createBrowserSupabaseClient } from '../supabaseClient';
import './CollectionList.css';

function CollectionList() {
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filterType, setFilterType] = useState('all'); // 'all', 'personal', or 'public'

  const filterCollections = useCallback(() => {
    let filtered = collections.filter(collection =>
      collection.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      collection.description.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (filterType === 'personal') {
      filtered = filtered.filter(collection => !collection.is_public);
    } else if (filterType === 'public') {
      filtered = filtered.filter(collection => collection.is_public);
    }

    setFilteredCollections(filtered);
  }, [collections, searchQuery, filterType]);

  useEffect(() => {
    fetchCollections();
  }, []);

  useEffect(() => {
    filterCollections();
  }, [searchQuery, collections, filterType, filterCollections]);

  async function fetchCollections() {
    setLoading(true);
    setError('');

    try {
      const supabase = createBrowserSupabaseClient();
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      
      if (userError) {
        throw new Error('User not authenticated');
      }

      // Fetch user collections
      const { data: userCollections, error: userCollectionsError } = await supabase
        .from('user_collections')
        .select('*')
        .eq('user_id', user.id);

      if (userCollectionsError) throw userCollectionsError;

      // Fetch public collections
      const { data: publicCollections, error: publicCollectionsError } = await supabase
        .from('public_collections')
        .select('*');

      if (publicCollectionsError) throw publicCollectionsError;

      // Combine and deduplicate collections
      const allCollections = [...userCollections, ...publicCollections.filter(pc => !userCollections.some(uc => uc.id === pc.id))];
      
      setCollections(allCollections);
      setFilteredCollections(allCollections);
    } catch (error) {
      console.error('Error fetching collections:', error);
      setError('Failed to fetch collections. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  if (loading) return <div className="loading">Loading collections...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <section className="collection-list section">
      <div className="container">
        <h2 className="glow-text">Collections</h2>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search collections..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
          <select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            className="filter-select"
          >
            <option value="all">All Collections</option>
            <option value="personal">My Collections</option>
            <option value="public">Public Collections</option>
          </select>
        </div>
        {filteredCollections.length === 0 ? (
          <p>No collections found.</p>
        ) : (
          <div className="collections-grid">
            {filteredCollections.map((collection) => (
              <div key={collection.id} className={`collection-card ${collection.is_public ? 'public' : 'personal'}`}>
                <Link to={`/collections/${collection.id}`}>
                  <h3>{collection.name}</h3>
                  <p>{collection.description}</p>
                  <p>Type: {collection.type}</p>
                  <p>Collection Type: {collection.collection_type}</p>
                  <p>Years: {collection.start_year} - {collection.end_year || 'Present'}</p>
                  <p>Total Coins: {collection.total_coins || 0}</p>
                  <p>Total Value: ${(collection.total_value || 0).toFixed(2)}</p>
                  <p>{collection.is_public ? 'Public Collection' : 'Personal Collection'}</p>
                  {collection.is_archived && <p>Archived</p>}
                  {collection.original_collection_id && <p>Copied from Public Collection</p>}
                </Link>
                <div className="progress-bar-container">
                  <div 
                    className="progress-bar" 
                    style={{ width: `${collection.completeness}%` }}
                  ></div>
                  <span className="progress-text">{`${Math.round(collection.completeness)}% Complete`}</span>
                </div>
              </div>
            ))}
          </div>
        )}
        <Link to="/create-collection" className="cta-button glow-button">Create New Collection</Link>
      </div>
    </section>
  );
}

export default CollectionList;