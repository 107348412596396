import React, { useState, useEffect, useCallback } from 'react';
import { createClient } from '../supabaseClient';

const metals = ['gold', 'silver', 'platinum', 'palladium'];
const supabase = createClient();

const RealTimeMetalPrices = () => {
  const [metalPrices, setMetalPrices] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchLatestPrices = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const promises = metals.map(async (metal) => {
        const { data, error } = await supabase
          .from(`${metal}_prices`)
          .select('date, price_in_usd')
          .order('date', { ascending: false })
          .limit(1);

        if (error) throw new Error(`Error fetching ${metal} price: ${error.message}`);

        return { 
          metal,
          price: data[0].price_in_usd,
          date: data[0].date
        };
      });

      const results = await Promise.all(promises);
      const pricesObject = results.reduce((acc, { metal, price }) => {
        acc[metal] = price;
        return acc;
      }, {});
      setMetalPrices(pricesObject);
    } catch (err) {
      console.error('Error fetching metal prices:', err);
      setError('Failed to fetch metal prices. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchLatestPrices();
    const interval = setInterval(fetchLatestPrices, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, [fetchLatestPrices]);

  if (loading) return <div className="loading">Loading metal prices...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="real-time-metal-prices">
      <h2>Real-Time Metal Prices</h2>
      {metals.map((metal) => (
        <div key={metal} className="metal-price">
          <span className="metal-name">{metal.charAt(0).toUpperCase() + metal.slice(1)}</span>
          <span className="metal-price-value">
            ${metalPrices[metal] ? metalPrices[metal].toFixed(2) : 'N/A'}
          </span>
        </div>
      ))}
      <button onClick={fetchLatestPrices} className="refresh-button">Refresh Prices</button>
    </div>
  );
};

export default RealTimeMetalPrices;