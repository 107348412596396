import React from 'react';

function Roadmap() {
  return (
    <section id="roadmap" className="roadmap section">
      <div className="container">
        <h2 className="glow-text">Our Vision for the Future</h2>
        <div className="timeline">
          <div className="milestone neon-border">
            <i className="icon fas fa-rocket"></i>
            <h3>Q1 2025</h3>
            <p>Full launch of MetalStackers Portfolio Tracker</p>
          </div>
          <div className="milestone neon-border">
            <i className="icon fas fa-code"></i>
            <h3>Q2 2025</h3>
            <p>Introduction of the Metals API</p>
          </div>
          <div className="milestone neon-border">
            <i className="icon fas fa-microchip"></i>
            <h3>Q3 2025</h3>
            <p>Launch of AI Coin Identification</p>
          </div>
        </div>
        <div className="highlight-box neon-border">
          <p>Stay with us and be the first to experience each new feature!</p>
        </div>
        <button className="cta-button secondary" onClick={() => alert('Newsletter signup functionality to be implemented')}>
          Stay Updated on Our Progress
        </button>
      </div>
    </section>
  );
}

export default Roadmap;