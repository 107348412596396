import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from '../supabaseClient';
import { useAuth } from '../AuthContext';

const AuthCallback = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { login } = useAuth();

  const TIMEOUT_DURATION = 30000; // 30 seconds timeout

  useEffect(() => {
    const isDevelopment = process.env.NODE_ENV === 'development';

    const handleAuthCallback = async () => {
      const supabase = createClient();
      let retries = 3;
      const timeout = setTimeout(() => {
        setError('Authentication process timed out. Please try again.');
        setLoading(false);
      }, TIMEOUT_DURATION);

      const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      const backoff = (attempt) => Math.min(1000 * 2 ** attempt, 10000);

      try {
        if (isDevelopment) {
          console.log('Starting OAuth callback process');
          console.log('Current URL:', window.location.href);
        }

        // Extract the code and state from the URL
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const state = params.get('state');
        const error = params.get('error');
        const errorDescription = params.get('error_description');

        if (error) {
          if (isDevelopment) console.error('Error in OAuth callback:', error, errorDescription);
          throw new Error(`OAuth error: ${error}. ${errorDescription}`);
        }

        if (!code) {
          if (isDevelopment) console.error('No code found in URL');
          throw new Error('No authentication code found. Please try logging in again.');
        }

        if (!state) {
          if (isDevelopment) console.error('No state found in URL');
          throw new Error('Invalid OAuth flow. Please try logging in again.');
        }

        // Verify state parameter
        const storedState = localStorage.getItem('oauthState');
        if (state !== storedState) {
          throw new Error('Invalid state parameter. Please try logging in again.');
        }
        localStorage.removeItem('oauthState'); // Clear the stored state

        if (isDevelopment) {
          console.log('Exchanging code for session');
          console.log('Code:', code);
          console.log('State:', state);
        }

        // Retry logic for code exchange with exponential backoff
        while (retries > 0) {
          try {
            const { data, error: exchangeError } = await supabase.auth.exchangeCodeForSession(code);
            if (isDevelopment) console.log('Exchange code for session result:', { data, error: exchangeError });

            if (exchangeError) {
              if (exchangeError.message.includes('invalid flow state')) {
                if (isDevelopment) console.error('Invalid flow state error:', exchangeError);
                throw new Error('Your login session has expired. Please try logging in again.');
              }
              throw exchangeError;
            }

            if (!data?.session) {
              throw new Error('No session found after code exchange');
            }

            if (isDevelopment) console.log('Authentication successful, session:', data.session);

            // Get user data
            if (isDevelopment) console.log('Fetching user data');
            const { data: userData, error: userError } = await supabase.auth.getUser();
            if (isDevelopment) console.log('User data:', userData);

            if (userError) {
              throw userError;
            }

            if (!userData?.user) {
              throw new Error('No user found after authentication');
            }

            if (isDevelopment) console.log('User:', userData.user);
            
            // Update global auth state
            login();

            clearTimeout(timeout);
            if (isDevelopment) console.log('Redirecting to dashboard');
            // Redirect to dashboard
            navigate('/dashboard');
            return; // Exit the function if successful
          } catch (error) {
            if (isDevelopment) console.error(`Error during code exchange (attempt ${4 - retries}/3):`, error);
            retries--;
            if (retries === 0) {
              throw error;
            }
            await wait(backoff(3 - retries));
          }
        }
      } catch (error) {
        clearTimeout(timeout);
        if (isDevelopment) {
          console.error('Error during authentication:', error);
          console.error('Error stack:', error.stack);
        }
        if (error.message.includes('PKCE')) {
          setError('Security verification failed. Please ensure you are using the latest login link and try again.');
        } else if (error.message.includes('state')) {
          setError('Invalid security state. Please close this window and try logging in again from the beginning.');
        } else if (error.message.includes('Invalid login credentials')) {
          setError('Invalid login credentials. Please check your email and password and try again.');
        } else if (error.message.includes('User not found')) {
          setError('User account not found. Please check your email or sign up for a new account.');
        } else if (error.message.includes('Email not confirmed')) {
          setError('Please confirm your email address before logging in. Check your inbox for a confirmation email.');
        } else if (error.message.includes('invalid flow state')) {
          setError('Your login session has expired. Please try logging in again.');
        } else {
          setError('An unexpected error occurred during authentication. Please try again or contact support if the issue persists.');
        }
      } finally {
        setLoading(false);
      }
    };

    handleAuthCallback();
  }, [navigate, login, TIMEOUT_DURATION]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
        <div style={{ border: '4px solid #f3f3f3', borderTop: '4px solid #3498db', borderRadius: '50%', width: '40px', height: '40px', animation: 'spin 1s linear infinite' }}></div>
        <p style={{ marginTop: '20px' }}>Authenticating... Please wait.</p>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', padding: '0 20px', textAlign: 'center' }}>
        <h2 style={{ color: '#e74c3c' }}>Authentication Error</h2>
        <p style={{ maxWidth: '400px', marginBottom: '20px' }}>{error}</p>
        <p style={{ maxWidth: '400px', marginBottom: '20px' }}>If you continue to experience issues, please contact our support team.</p>
        <button 
          onClick={() => navigate('/login')} 
          style={{ 
            marginTop: '20px', 
            padding: '10px 20px', 
            backgroundColor: '#3498db', 
            color: 'white', 
            border: 'none', 
            borderRadius: '5px', 
            cursor: 'pointer' 
          }}
        >
          Return to Login
        </button>
      </div>
    );
  }

  return null;
};

export default AuthCallback;
