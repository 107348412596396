import React, { useState, useEffect } from 'react';
import { createBrowserSupabaseClient } from '../supabaseClient';

const AddToMyCollections = ({ publicCollectionId, onCollectionAdded }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState(null);
  const [supabase, setSupabase] = useState(null);

  useEffect(() => {
    setSupabase(createBrowserSupabaseClient());
  }, []);

  const addToMyCollections = async () => {
    if (!supabase) return;

    setIsAdding(true);
    setError(null);

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('You must be logged in to add a collection.');
      }

      const { data, error } = await supabase.rpc('copy_collection_to_user', {
        p_user_id: user.id,
        p_public_collection_id: publicCollectionId
      });

      if (error) throw error;

      console.log('Collection added successfully:', data);
      onCollectionAdded(); // Notify the parent component that the collection was added
    } catch (error) {
      console.error('Error adding collection:', error);
      setError(error.message || 'An error occurred while adding the collection. Please try again.');
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <div className="add-to-my-collections">
      <button onClick={addToMyCollections} disabled={isAdding || !supabase}>
        {isAdding ? 'Adding...' : 'Add to My Collections'}
      </button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default AddToMyCollections;