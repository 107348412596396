import React, { useState, useEffect, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { createClient } from '../supabaseClient';
import './HistoricMetalPrices.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const metals = ['gold', 'silver', 'platinum', 'palladium'];
const supabase = createClient();

const HistoricMetalPrices = () => {
  const [selectedMetals, setSelectedMetals] = useState(['gold']);
  const [dateRange, setDateRange] = useState({ start: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), end: new Date() });
  const [chartData, setChartData] = useState(null);
  const [priceData, setPriceData] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateChartData = useCallback((data) => {
    const datasets = selectedMetals.map(metal => ({
      label: metal.charAt(0).toUpperCase() + metal.slice(1),
      data: data.filter(item => item.metal === metal).map(item => item.price_in_usd),
      borderColor: getMetalColor(metal),
      backgroundColor: getMetalColor(metal),
    }));

    setChartData({
      labels: data.filter(item => item.metal === selectedMetals[0]).map(item => item.date),
      datasets,
    });
  }, [selectedMetals]);

  const calculateStatistics = useCallback((data) => {
    const stats = {};
    selectedMetals.forEach(metal => {
      const metalData = data.filter(item => item.metal === metal);
      const prices = metalData.map(item => item.price_in_usd);
      stats[metal] = {
        high: Math.max(...prices),
        low: Math.min(...prices),
        average: prices.reduce((sum, price) => sum + price, 0) / prices.length,
      };
    });
    setStatistics(stats);
  }, [selectedMetals]);

  const fetchPriceData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const promises = selectedMetals.map(async (metal) => {
        const { data, error } = await supabase
          .from(`${metal}_prices`)
          .select('date, price_in_usd')
          .gte('date', dateRange.start.toISOString().split('T')[0])
          .lte('date', dateRange.end.toISOString().split('T')[0])
          .order('date', { ascending: true });

        if (error) throw new Error(`Error fetching ${metal} prices: ${error.message}`);

        return data.map(item => ({ ...item, metal }));
      });

      const results = await Promise.all(promises);
      const combinedData = results.flat();
      setPriceData(combinedData);
      updateChartData(combinedData);
      calculateStatistics(combinedData);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [selectedMetals, dateRange, updateChartData, calculateStatistics]);

  useEffect(() => {
    fetchPriceData();
  }, [fetchPriceData]);

  const handleMetalSelection = (metal) => {
    setSelectedMetals(prev => 
      prev.includes(metal) ? prev.filter(m => m !== metal) : [...prev, metal]
    );
  };

  const handleDateRangeChange = (dates) => {
    const [start, end] = dates;
    setDateRange({ start, end });
  };

  const getMetalColor = (metal) => {
    const colors = {
      gold: 'rgb(255, 215, 0)',
      silver: 'rgb(192, 192, 192)',
      platinum: 'rgb(229, 228, 226)',
      palladium: 'rgb(138, 7, 7)',
    };
    return colors[metal] || 'rgb(0, 0, 0)';
  };

  const downloadCSV = () => {
    const csvContent = 'data:text/csv;charset=utf-8,' + 
      'Date,' + selectedMetals.map(m => m.charAt(0).toUpperCase() + m.slice(1)).join(',') + '\n' +
      Object.entries(priceData.reduce((acc, row) => {
        if (acc.hasOwnProperty(row.date)) {
          acc[row.date][row.metal] = row.price_in_usd;
        } else {
          acc[row.date] = { [row.metal]: row.price_in_usd };
        }
        return acc;
      }, {})).map(([date, prices]) => 
        date + ',' + selectedMetals.map(metal => prices[metal] || '').join(',')
      ).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'metal_prices.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="historic-metal-prices">
      <h1>Historic Metal Prices</h1>
      
      <p className="description">
        View and compare historical prices for gold, silver, platinum, and palladium. 
        Select the metals you're interested in and choose a date range to analyze the data.
      </p>
      
      <div className="controls">
        <div className="metal-selection">
          {metals.map(metal => (
            <label key={metal} className="metal-checkbox">
              <input
                type="checkbox"
                checked={selectedMetals.includes(metal)}
                onChange={() => handleMetalSelection(metal)}
              />
              <span className="checkmark"></span>
              {metal.charAt(0).toUpperCase() + metal.slice(1)}
            </label>
          ))}
        </div>

        <div className="date-range-selection">
          <DatePicker
            selectsRange={true}
            startDate={dateRange.start}
            endDate={dateRange.end}
            onChange={handleDateRangeChange}
            dateFormat="yyyy-MM-dd"
          />
        </div>
      </div>

      <div className="price-chart">
        {chartData && <Line data={chartData} options={chartOptions} />}
      </div>

      <div className="price-statistics">
        {Object.entries(statistics).map(([metal, stats]) => (
          <div key={metal} className="metal-stats">
            <h3>{metal.charAt(0).toUpperCase() + metal.slice(1)}</h3>
            <p>High: ${stats.high.toFixed(2)}</p>
            <p>Low: ${stats.low.toFixed(2)}</p>
            <p>Average: ${stats.average.toFixed(2)}</p>
          </div>
        ))}
      </div>

      <div className="data-table-container">
        <table className="data-table">
          <thead>
            <tr>
              <th>Date</th>
              {selectedMetals.map(metal => (
                <th key={metal}>{metal.charAt(0).toUpperCase() + metal.slice(1)} Price (USD)</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(priceData.reduce((acc, row) => {
              if (acc.hasOwnProperty(row.date)) {
                acc[row.date][row.metal] = row.price_in_usd;
              } else {
                acc[row.date] = { [row.metal]: row.price_in_usd };
              }
              return acc;
            }, {})).map(([date, prices]) => (
              <tr key={date}>
                <td>{date}</td>
                {selectedMetals.map(metal => (
                  <td key={metal}>{prices[metal] ? `$${prices[metal].toFixed(2)}` : '-'}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="download-options">
        <button onClick={downloadCSV}>Download CSV</button>
      </div>
    </div>
  );
};

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        color: '#ffffff'
      }
    },
    title: {
      display: true,
      text: 'Metal Prices Over Time',
      color: '#d4af37'
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
          }
          return label;
        }
      },
      backgroundColor: '#0a0a1a',
      titleColor: '#d4af37',
      bodyColor: '#ffffff',
      borderColor: '#d4af37',
      borderWidth: 1
    }
  },
  scales: {
    y: {
      beginAtZero: false,
      title: {
        display: true,
        text: 'Price (USD)',
        color: '#ffffff'
      },
      ticks: {
        color: '#ffffff'
      },
      grid: {
        color: 'rgba(255, 255, 255, 0.1)'
      }
    },
    x: {
      title: {
        display: true,
        text: 'Date',
        color: '#ffffff'
      },
      ticks: {
        color: '#ffffff'
      },
      grid: {
        color: 'rgba(255, 255, 255, 0.1)'
      }
    },
  },
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false
  }
};

export default HistoricMetalPrices;