import React from 'react';
import './AboutUs.css';

const AboutUs = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="about-us-modal">
      <div className="about-us-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <div className="about-us-container">
          <section className="hero-section">
            <h1>About MetalStackers.io</h1>
            <h2>Empowering Precious Metal Investors with Innovative Tools</h2>
          </section>

          <section className="mission">
            <h2>Our Mission</h2>
            <div className="two-column-layout">
              <div className="text-column">
                <p>At MetalStackers.io, our mission is to empower individuals to manage their precious metal investments with ease, transparency, and confidence. We provide the best tools and resources for investors at all levels to make informed decisions and grow their portfolios.</p>
              </div>
              <div className="image-column">
                <i className="fas fa-bullseye fa-5x"></i>
              </div>
            </div>
          </section>

          <section className="story">
            <h2>Our Story</h2>
            <p>MetalStackers.io was founded by a group of passionate investors and tech enthusiasts who saw a gap in the market for a comprehensive, user-friendly platform dedicated to precious metals. Since our launch, we've been committed to creating an innovative solution that combines real-time market data, robust portfolio management tools, and a secure, modern interface.</p>
          </section>

          <section className="why-choose-us">
            <h2>Why Choose MetalStackers.io?</h2>
            <div className="feature-cards">
              <div className="feature-card">
                <i className="fas fa-chart-line fa-3x"></i>
                <h3>Real-time Market Data</h3>
                <p>Stay informed with up-to-the-minute market information.</p>
              </div>
              <div className="feature-card">
                <i className="fas fa-tools fa-3x"></i>
                <h3>Intuitive Portfolio Tools</h3>
                <p>Easily track and manage your precious metal investments.</p>
              </div>
              <div className="feature-card">
                <i className="fas fa-desktop fa-3x"></i>
                <h3>Modern User Interface</h3>
                <p>Enjoy a seamless experience with our sleek, user-friendly design.</p>
              </div>
            </div>
          </section>

          <section className="core-values">
            <h2>Our Core Values</h2>
            <div className="value-cards">
              <div className="value-card">
                <i className="fas fa-eye fa-3x"></i>
                <h3>Transparency</h3>
                <p>We provide clear, honest information to help you make informed investment decisions.</p>
              </div>
              <div className="value-card">
                <i className="fas fa-shield-alt fa-3x"></i>
                <h3>Security</h3>
                <p>Protecting your data is our top priority. Our platform is built with the latest security features.</p>
              </div>
              <div className="value-card">
                <i className="fas fa-users fa-3x"></i>
                <h3>Community</h3>
                <p>We're building a community of like-minded investors who share a passion for precious metals.</p>
              </div>
              <div className="value-card">
                <i className="fas fa-lightbulb fa-3x"></i>
                <h3>Innovation</h3>
                <p>We continuously strive to improve our platform and introduce new features to enhance your experience.</p>
              </div>
            </div>
          </section>

          <button className="close-btn" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;