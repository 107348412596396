import React, { useState, useEffect, useCallback } from 'react';
import { createBrowserSupabaseClient } from '../supabaseClient';
import './PriceTicker.css';

const metals = ['gold', 'silver', 'platinum', 'palladium'];

const PriceTicker = () => {
  const [supabase, setSupabase] = useState(null);
  const [metalPrices, setMetalPrices] = useState([]);

  useEffect(() => {
    setSupabase(createBrowserSupabaseClient());
  }, []);

  const fetchMetalPrices = useCallback(async () => {
    if (!supabase) return;
    try {
      const promises = metals.map(async (metal) => {
        const { data, error } = await supabase
          .from(`${metal}_prices`)
          .select('date, price_in_usd')
          .order('date', { ascending: false })
          .limit(1);

        if (error) throw new Error(`Error fetching ${metal} price: ${error.message}`);

        return { 
          name: metal.charAt(0).toUpperCase() + metal.slice(1), 
          price: data[0].price_in_usd,
          date: data[0].date
        };
      });

      const results = await Promise.all(promises);
      setMetalPrices(results);
    } catch (error) {
      console.error('Error fetching metal prices:', error);
    }
  }, [supabase]);

  useEffect(() => {
    if (supabase) {
      fetchMetalPrices();
      const interval = setInterval(fetchMetalPrices, 60000); // Update every minute
      return () => clearInterval(interval);
    }
  }, [supabase, fetchMetalPrices]);

  const renderTickerItem = (price) => (
    <div key={price.name} className="ticker-item">
      <div className="ticker-metal-name">{price.name}</div>
      <div className="ticker-price-info">
        <span className="ticker-price">
          {price.price !== undefined && price.price !== null ? `$${parseFloat(price.price).toFixed(2)}` : 'N/A'}
        </span>
      </div>
    </div>
  );

  return (
    <div className="price-ticker-container">
      <div className="price-ticker-wrapper">
        {metalPrices.length > 0 ? (
          metalPrices.map(renderTickerItem)
        ) : (
          <div className="ticker-item">Loading metal prices...</div>
        )}
      </div>
    </div>
  );
};

export default PriceTicker;