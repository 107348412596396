import React from 'react';

function Features() {
  return (
    <section id="features" className="features section">
      <div className="full-width-container">
        <div className="container">
          <div className="feature neon-border">
            <i className="icon fas fa-chart-line"></i>
            <h3>Stay Ahead with Real-Time Tracking</h3>
            <p>Monitor your metals portfolio in real-time with our intuitive dashboard, designed for quick insights and easy management.</p>
          </div>
          <div className="feature neon-border">
            <i className="icon fas fa-trophy"></i>
            <h3>Turn Investing into a Game</h3>
            <p>Earn badges, compete on leaderboards, and challenge your friends to see who can build the most successful portfolio.</p>
          </div>
          <div className="feature neon-border">
            <i className="icon fas fa-brain"></i>
            <h3>Unlock Advanced Insights</h3>
            <p>Utilize AI-driven insights and advanced analytics to make informed investment decisions. Our future AI coin identification feature will enhance your portfolio management.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;