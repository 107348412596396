import React, { useState, useEffect } from 'react';
import { createBrowserSupabaseClient, uploadProfilePicture, fetchUserCollections, updateUserProfile } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css';

function UserProfile() {
  const [user, setUser] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [bio, setBio] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [collections, setCollections] = useState([]);
  const [stackingExperience, setStackingExperience] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserProfile();
  }, []);

  async function fetchUserProfile() {
    try {
      setLoading(true);
      const supabase = createBrowserSupabaseClient();
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) throw error;
      if (user) {
        setUser(user);
        setName(user.user_metadata.name || '');
        setEmail(user.email || '');
        setPhone(user.user_metadata.phone || '');
        setBio(user.user_metadata.bio || '');
        setProfilePicture(user.user_metadata.avatar_url || null);
        const userCollections = await fetchUserCollections(user.id);
        setCollections(userCollections);
        
        // Fetch stacking experience
        const { data: profileData, error: profileError } = await supabase
          .from('profiles')
          .select('stacking_experience')
          .eq('id', user.id)
          .single();
        
        if (profileError) throw profileError;
        setStackingExperience(profileData.stacking_experience || 0);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError('Failed to fetch user profile');
    } finally {
      setLoading(false);
    }
  }

  async function updateProfile(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const updates = {
        name,
        phone,
        bio,
        avatar_url: profilePicture
      };
      await updateUserProfile(user.id, updates);
      const supabase = createBrowserSupabaseClient();
      await supabase.auth.updateUser({
        data: updates
      });
      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
    } finally {
      setLoading(false);
    }
  }

  async function handleProfilePictureUpload(e) {
    const file = e.target.files[0];
    try {
      setLoading(true);
      const publicUrl = await uploadProfilePicture(file, user.id);
      setProfilePicture(publicUrl);
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      setError('Failed to upload profile picture');
    } finally {
      setLoading(false);
    }
  }

  function handleCreateCollection() {
    navigate('/create-collection'); // Adjust this route as needed
  }

  function getStackingBadge(experience) {
    if (experience >= 1000) return '🏅';
    if (experience >= 500) return '🥈';
    if (experience >= 100) return '🥉';
    return '🔰';
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Please log in to view your profile.</div>;
  }

  return (
    <section className="user-profile section" id="profile">
      <div className="container">
        <h2 className="glow-text">User Profile</h2>
        <div className="profile-content">
          <div className="profile-picture">
            {profilePicture ? (
              <img src={profilePicture} alt="Profile" />
            ) : (
              <div className="profile-picture-placeholder">No Picture</div>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleProfilePictureUpload}
              style={{ display: 'none' }}
              id="profile-picture-upload"
            />
            <label htmlFor="profile-picture-upload" className="cta-button glow-button">
              Upload Profile Picture
            </label>
          </div>
          <form onSubmit={updateProfile} className="profile-form">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                id="phone"
                placeholder="Your Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="bio">Bio</label>
              <textarea
                id="bio"
                placeholder="Tell us about yourself and your collecting interests"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Stacking Experience</label>
              <div className="stacking-experience">
                <span className="experience-points">{stackingExperience} XP</span>
                <span className="experience-badge" title={`Stacking Experience: ${stackingExperience} XP`}>
                  {getStackingBadge(stackingExperience)}
                </span>
              </div>
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="cta-button glow-button" disabled={loading}>
              {loading ? 'Updating...' : 'Update Profile'}
            </button>
          </form>
        </div>
        <div className="collections-showcase">
          <h3 className="glow-text">My Collections</h3>
          {collections.length > 0 ? (
            <ul className="collections-list">
              {collections.map((collection) => (
                <li key={collection.id} className="collection-item">
                  <h4>{collection.name}</h4>
                  <p>{collection.description}</p>
                </li>
              ))}
            </ul>
          ) : (
            <div className="no-collections">
              <p>You haven't created any collections yet.</p>
              <button onClick={handleCreateCollection} className="cta-button glow-button">
                Get Started: Create Your First Collection
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default UserProfile;
