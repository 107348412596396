import React, { useState, useEffect } from 'react';
import { Table, Input, InputNumber, Form, Button, message } from 'antd';
import { createClient } from '../supabaseClient';
import './BulkEditAssets.css';

const BulkEditAssets = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAssets();
  }, []);

  const fetchAssets = async () => {
    setLoading(true);
    const supabase = createClient();
    const { data, error } = await supabase
      .from('public_assets')
      .select('*');
    
    if (error) {
      console.error('Error fetching assets:', error);
      message.error('Failed to fetch assets');
    } else {
      setData(data);
    }
    setLoading(false);
  };

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);
      if (index > -1) {
        const item = newData[index];
        const updatedItem = {
          ...item,
          ...row,
          totalValue: row.weight * row.quantity * (row.metal === 'Gold' ? 1800 : row.metal === 'Silver' ? 25 : row.metal === 'Platinum' ? 1000 : 2000) // Simplified price calculation
        };
        newData.splice(index, 1, updatedItem);
        
        const supabase = createClient();
        const { error } = await supabase
          .from('public_assets')
          .update(updatedItem)
          .eq('id', key);

        if (error) throw error;

        setData(newData);
        setEditingKey('');
        message.success('Asset updated successfully');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo);
      message.error('Failed to update asset');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%',
      editable: true,
    },
    {
      title: 'Year',
      dataIndex: 'year',
      width: '15%',
      editable: true,
    },
    {
      title: 'Metal',
      dataIndex: 'metal',
      width: '15%',
      editable: true,
    },
    {
      title: 'Weight (oz)',
      dataIndex: 'weight',
      width: '15%',
      editable: true,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      width: '15%',
      editable: true,
    },
    {
      title: 'Total Value',
      dataIndex: 'totalValue',
      width: '15%',
      render: (_, record) => `$${record.totalValue.toFixed(2)}`,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              onClick={() => save(record.id)}
              style={{ marginRight: 8 }}
              type="link"
            >
              Save
            </Button>
            <Button onClick={cancel} type="link">
              Cancel
            </Button>
          </span>
        ) : (
          <Button disabled={editingKey !== ''} onClick={() => edit(record)} type="link">
            Edit
          </Button>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'year' || col.dataIndex === 'weight' || col.dataIndex === 'quantity' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <div className="bulk-edit-assets">
      <h2>Bulk Edit Assets</h2>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
          loading={loading}
        />
      </Form>
    </div>
  );
};

export default BulkEditAssets;