import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Form, Input, Button, Switch, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { createClient } from '../supabaseClient';
import './ShareCollection.css';

const ShareCollection = ({ collectionId, isVisible, onClose }) => {
  const [form] = Form.useForm();
  const [shareLink, setShareLink] = useState('');
  const [isPublic, setIsPublic] = useState(false);

  const fetchShareSettings = useCallback(async () => {
    const supabase = createClient();
    const { data, error } = await supabase
      .from('collections')
      .select('is_public, share_id')
      .eq('id', collectionId)
      .single();

    if (error) {
      console.error('Error fetching share settings:', error);
      message.error('Failed to fetch share settings');
    } else {
      setIsPublic(data.is_public);
      if (data.share_id) {
        setShareLink(`${window.location.origin}/shared-collection/${data.share_id}`);
      }
    }
  }, [collectionId]);

  useEffect(() => {
    if (isVisible) {
      fetchShareSettings();
    }
  }, [isVisible, fetchShareSettings]);

  const handleSubmit = async (values) => {
    const supabase = createClient();
    const { data, error } = await supabase
      .from('collections')
      .update({
        is_public: isPublic,
        share_id: isPublic ? (values.customUrl || generateShareId()) : null
      })
      .eq('id', collectionId);

    if (error) {
      console.error('Error updating share settings:', error);
      message.error('Failed to update share settings');
    } else {
      message.success('Share settings updated successfully');
      if (isPublic) {
        setShareLink(`${window.location.origin}/shared-collection/${data[0].share_id}`);
      } else {
        setShareLink('');
      }
    }
  };

  const generateShareId = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      message.success('Share link copied to clipboard');
    });
  };

  return (
    <Modal
      title="Share Collection"
      visible={isVisible}
      onCancel={onClose}
      footer={null}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="isPublic" label="Make Collection Public">
          <Switch
            checked={isPublic}
            onChange={(checked) => setIsPublic(checked)}
          />
        </Form.Item>
        {isPublic && (
          <Form.Item name="customUrl" label="Custom URL (optional)">
            <Input placeholder="Enter a custom URL" />
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Settings
          </Button>
        </Form.Item>
      </Form>
      {shareLink && (
        <div className="share-link">
          <Input value={shareLink} readOnly />
          <Button icon={<CopyOutlined />} onClick={copyToClipboard}>
            Copy Link
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default ShareCollection;