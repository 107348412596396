import React from 'react';
import { Link } from 'react-router-dom';

const QuickActions = () => {
  return (
    <div className="quick-actions">
      <h2>Quick Actions</h2>
      <div className="action-buttons">
        <Link to="/add-asset" className="action-button">
          Add Asset
        </Link>
        <Link to="/create-collection" className="action-button">
          Create Collection
        </Link>
        <Link to="/market-prices" className="action-button">
          View Market Prices
        </Link>
      </div>
    </div>
  );
};

export default QuickActions;