import React, { useState } from 'react';
import { Form, Select, InputNumber, Button, DatePicker, Card, List, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { createClient } from '../supabaseClient';
import AutoSuggestSearch from './AutoSuggestSearch';
import './AdvancedSearch.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

const AdvancedSearch = () => {
  const [form] = Form.useForm();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const supabase = createClient();
    let query = supabase.from('public_assets').select('*');

    if (values.issuer) {
      query = query.ilike('issuer', `%${values.issuer}%`);
    }
    if (values.metal) {
      query = query.eq('metal', values.metal);
    }
    if (values.year) {
      query = query.eq('year', values.year);
    }
    if (values.weightRange) {
      query = query.gte('weight', values.weightRange[0]).lte('weight', values.weightRange[1]);
    }
    if (values.denomination) {
      query = query.ilike('denomination', `%${values.denomination}%`);
    }
    if (values.purityRange) {
      query = query.gte('purity', values.purityRange[0]).lte('purity', values.purityRange[1]);
    }
    if (values.mintage) {
      query = query.lte('mintage', values.mintage);
    }
    if (values.dateRange) {
      query = query.gte('created_at', values.dateRange[0].toISOString())
                   .lte('created_at', values.dateRange[1].toISOString());
    }

    const { data, error } = await query;

    if (error) {
      console.error('Error performing search:', error);
    } else {
      setSearchResults(data);
    }
    setLoading(false);
  };

  const handleAutoSuggestSelect = (value, option) => {
    form.setFieldsValue({ issuer: value });
  };

  return (
    <div className="advanced-search">
      <h2>Advanced Search</h2>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item name="issuer" label="Issuer">
          <AutoSuggestSearch
            placeholder="Enter issuer name"
            onSelect={handleAutoSuggestSelect}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item name="metal" label="Metal">
          <Select placeholder="Select metal">
            <Option value="Gold">Gold</Option>
            <Option value="Silver">Silver</Option>
            <Option value="Platinum">Platinum</Option>
            <Option value="Palladium">Palladium</Option>
          </Select>
        </Form.Item>
        <Form.Item name="year" label="Year">
          <InputNumber placeholder="Enter year" />
        </Form.Item>
        <Form.Item name="weightRange" label="Weight Range (g)">
          <Input.Group compact>
            <InputNumber style={{ width: '45%' }} placeholder="Min" />
            <Input style={{ width: '10%', textAlign: 'center' }} placeholder="~" disabled />
            <InputNumber style={{ width: '45%' }} placeholder="Max" />
          </Input.Group>
        </Form.Item>
        <Form.Item name="denomination" label="Denomination">
          <Input placeholder="Enter denomination" />
        </Form.Item>
        <Form.Item name="purityRange" label="Purity Range">
          <Input.Group compact>
            <InputNumber style={{ width: '45%' }} placeholder="Min" min={0} max={1} step={0.001} />
            <Input style={{ width: '10%', textAlign: 'center' }} placeholder="~" disabled />
            <InputNumber style={{ width: '45%' }} placeholder="Max" min={0} max={1} step={0.001} />
          </Input.Group>
        </Form.Item>
        <Form.Item name="mintage" label="Max Mintage">
          <InputNumber placeholder="Enter max mintage" />
        </Form.Item>
        <Form.Item name="dateRange" label="Date Added Range">
          <RangePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<SearchOutlined />} loading={loading}>
            Search
          </Button>
        </Form.Item>
      </Form>

      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={searchResults}
        renderItem={(item) => (
          <List.Item>
            <Card
              cover={<img alt={item.issuer + ' ' + item.year + ' ' + item.denomination} src={item.image_obverse || 'https://via.placeholder.com/150'} />}
              hoverable
            >
              <Card.Meta
                title={`${item.issuer} ${item.year} ${item.denomination}`}
                description={`${item.metal} - ${item.weight}${item.weight_unit}`}
              />
              <p>Purity: {item.purity}</p>
              <p>Mintage: {item.mintage || 'N/A'}</p>
              <p>Mint Mark: {item.mint_mark || 'N/A'}</p>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default AdvancedSearch;