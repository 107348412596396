import React from 'react';
import PropTypes from 'prop-types';
import './Error.css';

const Error = ({ message, details, onRetry }) => {
  return (
    <div className="error-container" role="alert" aria-live="assertive">
      <h2 className="error-title">Oops! Something went wrong.</h2>
      <p className="error-message">{message || "We're sorry for the inconvenience. Our team has been notified and we're working to fix the issue."}</p>
      {details && process.env.NODE_ENV === 'development' && (
        <details className="error-details">
          <summary>Error Details</summary>
          <pre>{details}</pre>
        </details>
      )}
      {onRetry && (
        <button onClick={onRetry} className="retry-button" aria-label="Try again">
          Try Again
        </button>
      )}
    </div>
  );
};

Error.propTypes = {
  message: PropTypes.string,
  details: PropTypes.string,
  onRetry: PropTypes.func,
};

export default Error;