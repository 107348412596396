import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// For a better development experience, install React DevTools:
// https://reactjs.org/link/react-devtools

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);