import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getUser, signOut, createBrowserSupabaseClient } from '../supabaseClient';
import { Doughnut, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, Filler } from 'chart.js';
import { useSpring, animated } from 'react-spring';
import { Responsive, WidthProvider } from 'react-grid-layout';
import AutoSuggestSearch from './AutoSuggestSearch';
import AssetRecommendations from './AssetRecommendations';
import ErrorBoundary from './ErrorBoundary';
import QuickStats from './QuickStats';
import RealTimeMetalPrices from './RealTimeMetalPrices';
import NewsFeed from './NewsFeed';
import QuickActions from './QuickActions';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './Dashboard.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, Filler);

const Dashboard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [supabase] = useState(() => createBrowserSupabaseClient());
  const [loading, setLoading] = useState(true);
  const [totalValue, setTotalValue] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [portfolioChange, setPortfolioChange] = useState(0);
  const [topPerformers, setTopPerformers] = useState([]);
  const [collections, setCollections] = useState([]);
  const [newsItems, setNewsItems] = useState([]);
  const [metalPrices, setMetalPrices] = useState({});
  const [error, setError] = useState(null);
  const [assetAllocationData, setAssetAllocationData] = useState({ labels: [], datasets: [] });
  const [performanceData, setPerformanceData] = useState({ labels: [], datasets: [] });

  const [widgetLoadingStates, setWidgetLoadingStates] = useState({
    portfolioSummary: true,
    assetAllocation: true,
    topPerformers: true,
    collections: true,
    realTimePrices: true,
    performanceChart: true,
    newsFeed: true,
  });

  const [widgetErrors, setWidgetErrors] = useState({
    portfolioSummary: null,
    assetAllocation: null,
    topPerformers: null,
    collections: null,
    realTimePrices: null,
    performanceChart: null,
    newsFeed: null,
  });

  const [layouts, setLayouts] = useState({
    lg: [
      { i: 'portfolio-summary', x: 0, y: 0, w: 4, h: 2 },
      { i: 'asset-allocation', x: 4, y: 0, w: 4, h: 4 },
      { i: 'quick-stats', x: 8, y: 0, w: 4, h: 2 },
      { i: 'top-performers', x: 0, y: 2, w: 4, h: 3 },
      { i: 'collections', x: 4, y: 4, w: 4, h: 3 },
      { i: 'real-time-prices', x: 8, y: 2, w: 4, h: 3 },
      { i: 'performance-chart', x: 0, y: 5, w: 8, h: 4 },
      { i: 'news-feed', x: 8, y: 5, w: 4, h: 4 },
      { i: 'quick-actions', x: 0, y: 9, w: 6, h: 2 },
      { i: 'auto-suggest-search', x: 6, y: 9, w: 6, h: 2 },
      { i: 'asset-recommendations', x: 0, y: 11, w: 12, h: 3 }
    ],
    md: [
      { i: 'portfolio-summary', x: 0, y: 0, w: 5, h: 2 },
      { i: 'asset-allocation', x: 5, y: 0, w: 5, h: 4 },
      { i: 'quick-stats', x: 0, y: 2, w: 5, h: 2 },
      { i: 'top-performers', x: 0, y: 4, w: 5, h: 3 },
      { i: 'collections', x: 5, y: 4, w: 5, h: 3 },
      { i: 'real-time-prices', x: 0, y: 7, w: 3, h: 3 },
      { i: 'performance-chart', x: 3, y: 7, w: 7, h: 4 },
      { i: 'news-feed', x: 0, y: 11, w: 5, h: 3 },
      { i: 'quick-actions', x: 5, y: 11, w: 5, h: 2 },
      { i: 'auto-suggest-search', x: 0, y: 14, w: 10, h: 2 },
      { i: 'asset-recommendations', x: 0, y: 16, w: 10, h: 3 }
    ],
    sm: [
      { i: 'portfolio-summary', x: 0, y: 0, w: 6, h: 2 },
      { i: 'asset-allocation', x: 0, y: 2, w: 6, h: 4 },
      { i: 'quick-stats', x: 0, y: 6, w: 6, h: 2 },
      { i: 'top-performers', x: 0, y: 8, w: 6, h: 3 },
      { i: 'collections', x: 0, y: 11, w: 6, h: 3 },
      { i: 'real-time-prices', x: 0, y: 14, w: 6, h: 3 },
      { i: 'performance-chart', x: 0, y: 17, w: 6, h: 4 },
      { i: 'news-feed', x: 0, y: 21, w: 6, h: 3 },
      { i: 'quick-actions', x: 0, y: 24, w: 6, h: 2 },
      { i: 'auto-suggest-search', x: 0, y: 26, w: 6, h: 2 },
      { i: 'asset-recommendations', x: 0, y: 28, w: 6, h: 3 }
    ]
  });

  const setWidgetLoading = (widgetName, isLoading) => {
    setWidgetLoadingStates(prev => ({ ...prev, [widgetName]: isLoading }));
  };

  const setWidgetError = (widgetName, error) => {
    setWidgetErrors(prev => ({ ...prev, [widgetName]: error }));
  };

  const fetchAssets = useCallback(async (userId) => {
    setWidgetLoading('portfolioSummary', true);
    try {
      // Implement fetching assets logic
      const assets = []; // Placeholder
      setWidgetLoading('portfolioSummary', false);
      return assets;
    } catch (error) {
      console.error('Error fetching assets:', error);
      setWidgetError('portfolioSummary', 'Failed to fetch assets. Please try again.');
      setWidgetLoading('portfolioSummary', false);
      return [];
    }
  }, []);

  const fetchAssetAllocation = useCallback(async (assets) => {
    setWidgetLoading('assetAllocation', true);
    try {
      // This is a placeholder. In a real application, you would calculate this based on actual data
      const mockAllocation = {
        'Gold': 40,
        'Silver': 30,
        'Platinum': 20,
        'Palladium': 10
      };

      const labels = Object.keys(mockAllocation);
      const data = Object.values(mockAllocation);
      const backgroundColor = [
        'rgba(255, 206, 86, 0.8)',
        'rgba(75, 192, 192, 0.8)',
        'rgba(153, 102, 255, 0.8)',
        'rgba(255, 159, 64, 0.8)'
      ];
      const hoverBackgroundColor = backgroundColor.map(color => color.replace('0.8', '1'));

      setAssetAllocationData({
        labels,
        datasets: [{
          data,
          backgroundColor,
          hoverBackgroundColor
        }]
      });
      setWidgetLoading('assetAllocation', false);
    } catch (error) {
      console.error('Error fetching asset allocation:', error);
      setWidgetError('assetAllocation', 'Failed to fetch asset allocation. Please try again.');
      setWidgetLoading('assetAllocation', false);
    }
  }, []);

  const fetchPerformanceData = useCallback(async (userId) => {
    try {
      // This is a placeholder. In a real application, you would fetch this data from your API
      const mockPerformance = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        data: [10000, 12000, 11500, 13000, 14500, 15000]
      };

      setPerformanceData({
        labels: mockPerformance.labels,
        datasets: [{
          label: 'Portfolio Value',
          data: mockPerformance.data,
          fill: true,
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)'
        }]
      });
    } catch (error) {
      console.error('Error fetching performance data:', error);
    }
  }, []);

  const fetchTopPerformers = useCallback(async (userId) => {
    try {
      // Implement top performers fetching logic
      const mockTopPerformers = [
        { name: 'Gold Asset', value: 1000, change: 5.5 },
        { name: 'Silver Bar', value: 500, change: 3.2 },
      ];
      setTopPerformers(mockTopPerformers);
    } catch (error) {
      console.error('Error fetching top performers:', error);
    }
  }, []);

  const fetchCollections = useCallback(async (userId) => {
    setWidgetLoading('collections', true);
    try {
      const { data, error } = await supabase
        .from('user_collections')
        .select('*')
        .eq('user_id', userId);

      if (error) throw error;

      setCollections(data);
      setWidgetLoading('collections', false);
    } catch (error) {
      console.error('Error fetching collections:', error);
      setWidgetError('collections', 'Failed to fetch collections. Please try again.');
      setWidgetLoading('collections', false);
    }
  }, [supabase]);

  const calculateQuickStats = useCallback(async (assets) => {
    try {
      // Implement quick stats calculation logic
      const totalCost = assets.reduce((sum, asset) => sum + asset.cost, 0);
      const totalValue = assets.reduce((sum, asset) => sum + asset.value, 0);
      const change = ((totalValue - totalCost) / totalCost) * 100;

      setTotalCost(totalCost);
      setTotalValue(totalValue);
      setPortfolioChange(change);
    } catch (error) {
      console.error('Error calculating quick stats:', error);
    }
  }, []);

  const fetchNews = useCallback(async () => {
    try {
      // This is a placeholder. In a real application, you would fetch news from an API
      const mockNews = [
        { id: 1, title: "Gold prices surge amid economic uncertainty", date: "2023-07-10" },
        { id: 2, title: "Silver demand increases in industrial sector", date: "2023-07-09" },
        { id: 3, title: "Platinum market shows signs of recovery", date: "2023-07-08" },
      ];
      setNewsItems(mockNews);
    } catch (error) {
      console.error('Error fetching news:', error);
    }
  }, []);

  const fetchMetalPrices = useCallback(async () => {
    try {
      const metals = ['gold', 'silver', 'platinum', 'palladium'];
      const promises = metals.map(async (metal) => {
        const { data, error } = await supabase
          .from(`${metal}_prices`)
          .select('date, price_in_usd')
          .order('date', { ascending: false })
          .limit(1);

        if (error) throw new Error(`Error fetching ${metal} price: ${error.message}`);

        return { 
          name: metal,
          price: data[0].price_in_usd,
          date: data[0].date
        };
      });

      const results = await Promise.all(promises);
      const pricesObject = results.reduce((acc, { name, price }) => {
        acc[name] = price;
        return acc;
      }, {});
      setMetalPrices(pricesObject);
    } catch (error) {
      console.error('Error fetching metal prices:', error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Intentionally omitting 'supabase' from the dependency array

  const calculateTotalValue = useCallback((assets) => {
    return assets.reduce((total, asset) => total + asset.value, 0);
  }, []);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const currentUser = await getUser();
        if (!currentUser) {
          navigate('/login');
          return;
        }
        setUser(currentUser);

        const fetchedAssets = await fetchAssets(currentUser.id);

        await Promise.all([
          calculateTotalValue(fetchedAssets),
          fetchAssetAllocation(fetchedAssets),
          fetchTopPerformers(currentUser.id),
          fetchCollections(currentUser.id),
          fetchPerformanceData(currentUser.id),
          calculateQuickStats(fetchedAssets),
          fetchNews(),
          fetchMetalPrices()
        ]);
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setError('An error occurred while loading the dashboard. Please try again later.');
        setLoading(false);
      }
    };

    fetchDashboardData();

    // Set up an interval to fetch metal prices every minute
    const priceInterval = setInterval(fetchMetalPrices, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(priceInterval);
  }, [navigate, fetchAssets, calculateTotalValue, fetchAssetAllocation, fetchTopPerformers, fetchCollections, fetchPerformanceData, calculateQuickStats, fetchNews, fetchMetalPrices]);

  const handleLogout = async () => {
    await signOut();
    navigate('/login');
  };
  const AnimatedValue = ({ value }) => {
    const props = useSpring({ value, from: { value: 0 } });
    return <animated.span>{props.value.to((val) => val.toFixed(2))}</animated.span>;
  };

  const onLayoutChange = (layout, layouts) => {
    setLayouts(layouts);
  };

  const renderWidget = (widgetName, content) => {
    return (
      <ErrorBoundary>
        <div className="widget-content">
          {widgetLoadingStates[widgetName] ? (
            <div className="widget-loading">Loading...</div>
          ) : widgetErrors[widgetName] ? (
            <div className="widget-error">{widgetErrors[widgetName]}</div>
          ) : (
            content
          )}
        </div>
      </ErrorBoundary>
    );
  };

  const widgetComponents = {
    'portfolio-summary': renderWidget('portfolioSummary', (
      <>
        <h2>Portfolio Summary</h2>
        <p className="total-value">Total Value: $<AnimatedValue value={totalValue} /></p>
        <p className="total-cost">Total Cost: $<AnimatedValue value={totalCost} /></p>
        <p className="portfolio-change">
          Portfolio Change: 
          <span className={portfolioChange >= 0 ? 'positive' : 'negative'}>
            <AnimatedValue value={portfolioChange} />%
          </span>
        </p>
      </>
    )),
    'asset-allocation': renderWidget('assetAllocation', (
      <>
        <h2>Asset Allocation</h2>
        {assetAllocationData.labels.length > 0 ? (
          <Doughnut 
            data={assetAllocationData} 
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'right',
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      let label = context.label || '';
                      if (label) {
                        label += ': ';
                      }
                      if (context.parsed !== null) {
                        label += context.parsed + '%';
                      }
                      return label;
                    }
                  }
                }
              }
            }} 
          />
        ) : (
          <p>No assets to display</p>
        )}
      </>
    )),
    'quick-stats': renderWidget('quickStats', <QuickStats />),
    'top-performers': renderWidget('topPerformers', (
      <>
        <h2>Top Performers</h2>
        {topPerformers.length > 0 ? (
          <ul>
            {topPerformers.map((asset, index) => (
              <li key={index}>
                <span>{asset.name}</span>
                <span>${asset.value.toFixed(2)}</span>
                <span className={asset.change > 0 ? 'positive' : 'negative'}>
                  {asset.change > 0 ? '+' : ''}{asset.change.toFixed(2)}%
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p>No top performers to display</p>
        )}
      </>
    )),
    'collections': renderWidget('collections', (
      <>
        <div className="widget-header">
          <h2>My Collections</h2>
          <Link to="/create-collection" className="create-collection-button">+</Link>
        </div>
        {collections.length > 0 ? (
          <ul className="collections-list">
            {collections.map((collection) => (
              <li key={collection.id} className="collection-item">
                <h4>{collection.name}</h4>
                <p>Type: {collection.collection_type}</p>
                <p>Total Assets: {collection.total_assets}</p>
                <p>Total Value: ${collection.total_value.toFixed(2)}</p>
                <p>Completeness: {collection.completeness.toFixed(2)}%</p>
                {collection.is_archived && <p className="archived">Archived</p>}
              </li>
            ))}
          </ul>
        ) : (
          <p>You haven't created any collections yet.</p>
        )}
      </>
    )),
    'real-time-prices': renderWidget('realTimePrices', <RealTimeMetalPrices metalPrices={metalPrices} />),
    'performance-chart': renderWidget('performanceChart', (
      <>
        <h2>Portfolio Performance</h2>
        {performanceData.labels.length > 0 ? (
          <Line 
            data={performanceData} 
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'Portfolio Value Over Time'
                },
                tooltip: {
                  mode: 'index',
                  intersect: false,
                }
              },
              hover: {
                mode: 'nearest',
                intersect: true
              },
              scales: {
                x: {
                  display: true,
                  title: {
                    display: true,
                    text: 'Month'
                  }
                },
                y: {
                  display: true,
                  title: {
                    display: true,
                    text: 'Value ($)'
                  },
                  suggestedMin: 0,
                }
              }
            }}
          />
        ) : (
          <p>No performance data available</p>
        )}
      </>
    )),
    'news-feed': renderWidget('newsFeed', <NewsFeed newsItems={newsItems} />),
    'quick-actions': <QuickActions />,
    'auto-suggest-search': <AutoSuggestSearch />,
    'asset-recommendations': <AssetRecommendations userId={user?.id} />
  };

  if (loading) {
    return <div className="loading">Loading dashboard...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <h1>Your Dashboard</h1>
        {user && <p className="welcome-message">Welcome, {user.email}!</p>}
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </div>
      
      <ResponsiveGridLayout
        className="dashboard-grid"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={100}
        width="100%"
        onLayoutChange={onLayoutChange}
        isDraggable={true}
        isResizable={true}
        margin={[20, 20]}
      >
        {layouts.lg.map((item) => (
          <div key={item.i} className="widget">
            {widgetComponents[item.i]}
          </div>
        ))}
      </ResponsiveGridLayout>
    </div>
  );
};

export default Dashboard;
