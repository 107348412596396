// SendFox API functionality

const SENDFOX_API_URL = process.env.REACT_APP_SENDFOX_API_URL;
const SENDFOX_LIST_ID = process.env.REACT_APP_SENDFOX_LIST_ID;
const SENDFOX_API_KEY = process.env.REACT_APP_SENDFOX_API_KEY;

export async function addContactToSendFox(email, firstName, lastName) {
    const data = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        lists: [SENDFOX_LIST_ID]
    };

    try {
        const response = await fetch(SENDFOX_API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SENDFOX_API_KEY}`
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Contact added successfully:', result);
        return result;
    } catch (error) {
        console.error('Error adding contact to SendFox:', error);
        throw error;
    }
}