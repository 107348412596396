import React, { useState } from 'react';
import { Form, Input, Select, Button, InputNumber, DatePicker, Switch, Divider } from 'antd';
import moment from 'moment';

const { Option } = Select;

const AssetForm = ({ initialValues, onSubmit, formType }) => {
  const [form] = Form.useForm();
  const [isAdvanced, setIsAdvanced] = useState(formType === 'advanced');

  const metalTypes = ['Gold', 'Silver', 'Platinum', 'Palladium', 'Rhodium'];

  const assetTypes = [
    'Coins',
    'Bars',
    'Jewelry',
    'Grains',
    'Rounds',
    'Ingots',
    'Bullion',
    'Medals',
    'Wafers',
    'Scrap'
  ];

  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        purchase_date: initialValues.purchase_date ? moment(initialValues.purchase_date) : null,
        last_valuation_date: initialValues.last_valuation_date ? moment(initialValues.last_valuation_date) : null,
      });
    }
  }, [initialValues, form]);

  const handleSubmit = (values) => {
    onSubmit({
      ...values,
      purchase_date: values.purchase_date ? values.purchase_date.format('YYYY-MM-DD') : null,
      last_valuation_date: values.last_valuation_date ? values.last_valuation_date.format('YYYY-MM-DD') : null,
    });
  };

  const toggleFormType = () => {
    setIsAdvanced(!isAdvanced);
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Form.Item label="Form Type">
        <Switch
          checkedChildren="Advanced"
          unCheckedChildren="Basic"
          checked={isAdvanced}
          onChange={toggleFormType}
        />
      </Form.Item>

      <Divider />

      <Form.Item name="metal" label="Metal" rules={[{ required: true, message: 'Please select a metal' }]}>
        <Select>
          {metalTypes.map((metal) => (
            <Option key={metal} value={metal}>
              {metal}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="asset_type" label="Asset Type" rules={[{ required: true, message: 'Please select an asset type' }]}>
        <Select>
          {assetTypes.map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="weight" label="Weight" rules={[{ required: true, message: 'Please enter the weight' }]}>
        <InputNumber min={0} step={0.001} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name="weight_unit" label="Weight Unit" rules={[{ required: true, message: 'Please select a weight unit' }]}>
        <Select>
          <Option value="oz">oz</Option>
          <Option value="g">g</Option>
          <Option value="kg">kg</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="purity"
        label="Purity"
        rules={[{ type: 'number', min: 0, max: 1, message: 'Purity must be between 0 and 1' }]}
      >
        <InputNumber min={0} max={1} step={0.01} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name="quantity" label="Quantity" rules={[{ required: true, message: 'Please enter the quantity' }]}>
        <InputNumber min={1} style={{ width: '100%' }} />
      </Form.Item>

      {isAdvanced && (
        <>
          <Form.Item name="year" label="Year">
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name="denomination" label="Denomination">
            <Input />
          </Form.Item>

          <Form.Item name="mint_mark" label="Mint Mark">
            <Input />
          </Form.Item>

          <Form.Item name="is_proof" label="Is Proof" valuePropName="checked">
            <Switch />
          </Form.Item>

          <Form.Item name="condition" label="Condition">
            <Input />
          </Form.Item>

          <Form.Item name="storage_location" label="Storage Location">
            <Input />
          </Form.Item>

          <Form.Item name="acquisition_source" label="Acquisition Source">
            <Input />
          </Form.Item>

          <Form.Item name="category" label="Category">
            <Input />
          </Form.Item>
        </>
      )}

      <Form.Item name="purchase_date" label="Purchase Date">
        <DatePicker style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name="purchase_price" label="Purchase Price">
        <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name="currency" label="Currency" initialValue="USD">
        <Select>
          <Option value="USD">USD</Option>
          <Option value="EUR">EUR</Option>
          <Option value="GBP">GBP</Option>
        </Select>
      </Form.Item>

      {isAdvanced && (
        <>
          <Form.Item name="current_market_value" label="Current Market Value">
            <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name="last_valuation_date" label="Last Valuation Date">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </>
      )}

      <Form.Item name="notes" label="Notes">
        <Input.TextArea />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {initialValues ? 'Update Asset' : 'Add Asset'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AssetForm;