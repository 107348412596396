import React, { useState, useEffect, useCallback } from 'react';
import { Card, Col, Row, Spin, Typography, Alert } from 'antd';
import { createClient } from '../supabaseClient';
import './AssetRecommendations.css';

const { Meta } = Card;
const { Title } = Typography;

const AssetRecommendations = ({ userId, assetId }) => {
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchRecommendations = useCallback(async () => {
    if (!assetId) {
      setError("No asset selected. Please choose an asset to see recommendations.");
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const supabase = createClient();
      // Fetch the current asset's properties
      const { data: currentAsset, error: currentAssetError } = await supabase
        .from('public_assets')
        .select('metal, year')
        .eq('id', assetId)
        .single();

      if (currentAssetError) throw currentAssetError;

      if (!currentAsset) {
        throw new Error(`Asset with id ${assetId} not found`);
      }

      // Fetch similar assets
      const { data: similarAssets, error: assetError } = await supabase
        .from('public_assets')
        .select('*')
        .eq('metal', currentAsset.metal)
        .gte('year', currentAsset.year - 5)
        .lte('year', currentAsset.year + 5)
        .not('id', 'eq', assetId)
        .limit(5);

      if (assetError) throw assetError;

      setRecommendations(similarAssets);
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      setError(`Failed to fetch recommendations: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [assetId]); // Removed userId from the dependency array

  useEffect(() => {
    fetchRecommendations();
  }, [fetchRecommendations]);

  if (loading) {
    return <Spin size="large" />;
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />;
  }

  return (
    <div className="asset-recommendations">
      <Title level={3}>Recommended Assets</Title>
      <Row gutter={[16, 16]}>
        {recommendations.map((asset) => (
          <Col xs={24} sm={12} md={8} lg={6} key={asset.id}>
            <Card
              hoverable
              cover={<img alt={asset.name} src={asset.image_url || 'https://via.placeholder.com/150'} />}
            >
              <Meta title={asset.name} description={`${asset.year} ${asset.metal} - ${asset.weight}oz`} />
              <p>Price: ${asset.price}</p>
            </Card>
          </Col>
        ))}
      </Row>
      {recommendations.length === 0 && <Alert message="No recommendations available at this time." type="info" showIcon />}
    </div>
  );
};

export default AssetRecommendations;