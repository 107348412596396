import React from 'react';
import './Contribute.css';

// SVG icon for Litecoin
const LitecoinIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.6 82.6" width="48" height="48" fill="currentColor">
    <path d="M41.3,0C18.5,0,0,18.5,0,41.3s18.5,41.3,41.3,41.3s41.3-18.5,41.3-41.3S64.1,0,41.3,0z M42.5,53.8l-3.8,13.1h22.3
    c0.7,0,1.3,0.5,1.5,1.2l2.3,7.8c0.1,0.5-0.2,1-0.7,1.1c-0.1,0-0.2,0-0.2,0H32.8l5.2-18.1l-6.9,2l1.5-5.2l6.9-2l7.3-25.5
    c0.2-0.7,0.9-1.2,1.6-1.2h9.2c0.5,0,0.9,0.4,0.9,0.9c0,0.1,0,0.2,0,0.3l-6.2,21.4l6.9-2l-1.6,5.7L42.5,53.8z"/>
  </svg>
);

const Contribute = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="contribute-modal">
      <div className="contribute-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <div className="contribute-container">
          <section className="hero-section">
            <h1>Be a Part of the MetalStackers.io Community</h1>
            <h2>Your contribution can help shape the future of precious metal investing.</h2>
          </section>

          <section className="why-contribute">
            <h2>Why Contribute?</h2>
            <div className="two-column-layout">
              <div className="text-column">
                <p>At MetalStackers.io, we believe in the power of community. Your support helps us improve our platform, introduce new features, and ensure that everyone can manage their precious metal investments with confidence and ease. By contributing, you're helping us build a stronger, more inclusive community for metal investors everywhere.</p>
              </div>
              <div className="image-column">
                <i className="fas fa-users fa-5x"></i>
              </div>
            </div>
          </section>

          <section className="donate-crypto">
            <h2>Donate with Cryptocurrency</h2>
            <p>Support MetalStackers.io with your favorite cryptocurrency! Your donations help us maintain and develop new features that make our platform even better.</p>
            <div className="crypto-addresses">
              <div className="crypto-item">
                <i className="fab fa-bitcoin fa-3x"></i>
                <p>Bitcoin (BTC): 1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa</p>
              </div>
              <div className="crypto-item">
                <i className="fab fa-ethereum fa-3x"></i>
                <p>Ethereum (ETH): 0xAbC12345Ef6789DeF01234ABc56789ef01234567</p>
              </div>
              <div className="crypto-item">
                <LitecoinIcon />
                <p>Litecoin (LTC): Labc12345Def6789Ghi01234JKl56789mno01234P</p>
              </div>
            </div>
            <p>Scan the QR code below or copy the wallet address to donate:</p>
            {/* Add QR codes for each cryptocurrency */}
          </section>

          <section className="join-team">
            <h2>Join Our Team</h2>
            <p>Are you a developer, designer, or have a unique skill that can help MetalStackers.io grow? We're always looking for talented individuals to join our team and contribute to our mission. Whether you're an experienced professional or just starting out, we have opportunities for you to make an impact.</p>
            <div className="role-cards">
              <div className="role-card">
                <i className="fas fa-code fa-3x"></i>
                <h3>Developers</h3>
                <p>Help us build new features and improve our platform.</p>
                <button>Learn More</button>
              </div>
              <div className="role-card">
                <i className="fas fa-paint-brush fa-3x"></i>
                <h3>Designers</h3>
                <p>Create beautiful, user-friendly interfaces and experiences.</p>
                <button>Learn More</button>
              </div>
              <div className="role-card">
                <i className="fas fa-pen-fancy fa-3x"></i>
                <h3>Content Creators</h3>
                <p>Help us create engaging content for our users.</p>
                <button>Learn More</button>
              </div>
              <div className="role-card">
                <i className="fas fa-users fa-3x"></i>
                <h3>Community Managers</h3>
                <p>Engage with our community and help us grow.</p>
                <button>Learn More</button>
              </div>
            </div>
          </section>

          <section className="how-to-get-involved">
            <h2>How to Get Involved</h2>
            <ol>
              <li><i className="fas fa-donate"></i> Donate: Support us financially by donating with cryptocurrency. Every little bit helps us grow.</li>
              <li><i className="fas fa-user-plus"></i> Join the Team: Apply for a role and start contributing your skills to MetalStackers.io.</li>
              <li><i className="fas fa-share-alt"></i> Spread the Word: Share MetalStackers.io with your friends and followers to help us grow our community.</li>
            </ol>
            <p>Have questions or ideas? Contact us at contact@metalstackers.io.</p>
          </section>

          <section className="community-shoutouts">
            <h2>Community Shoutouts</h2>
            <p>We are grateful for the support and contributions of our amazing community members. Here are just a few of the incredible people who have helped make MetalStackers.io what it is today.</p>
            {/* Add a carousel or grid of contributor profiles here */}
          </section>

          <div className="bottom-close-button-container">
            <button className="bottom-close-button" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contribute;