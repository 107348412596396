import React from 'react';
import './TermsOfService.css';

const TermsOfService = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="tos-modal">
      <div className="tos-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <div className="tos-container">
          <section className="hero-section">
            <h1>Terms of Service</h1>
            <h2>MetalStackers.io</h2>
          </section>

          <section className="effective-date">
            <h2>Effective Date: [Insert Date]</h2>
          </section>

          <section className="welcome">
            <p>Welcome to MetalStackers.io! Your privacy is important to us, and these Terms of Service explain how we collect, use, share, and protect your information when you visit our website or use our services. By using MetalStackers.io, you agree to the practices described in these Terms of Service.</p>
          </section>

          <section className="information-collection">
            <h2>1. Information We Collect</h2>
            <p>We collect the following types of information:</p>
            <ul>
              <li><strong>Personal Information:</strong> This includes information you provide to us directly, such as your name, email address, and any other contact information you submit when signing up for our newsletter, becoming a beta tester, or contacting us.</li>
              <li><strong>Usage Data:</strong> We collect information about your interaction with our website, such as your IP address, browser type, operating system, referral URLs, pages visited, links clicked, and other actions you take on our site.</li>
              <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to collect information about your browsing behavior and preferences. This helps us improve our website and provide a better user experience.</li>
            </ul>
          </section>

          <section className="information-usage">
            <h2>2. How We Use Your Information</h2>
            <p>We use the information we collect for various purposes, including:</p>
            <ul>
              <li>To Provide and Improve Our Services</li>
              <li>To Communicate with You</li>
              <li>To Process Transactions</li>
              <li>To Analyze Usage</li>
              <li>To Ensure Security and Compliance</li>
            </ul>
          </section>

          <section className="affiliate-marketing">
            <h2>3. Affiliate Marketing</h2>
            <p>MetalStackers.io participates in affiliate marketing programs, which means we may earn commissions on purchases made through links to affiliate partners, such as APMEX and SD Bullion. These affiliate links do not affect the price you pay and are used to support our platform. We do not share your personal information with our affiliate partners unless explicitly stated.</p>
          </section>

          <section className="data-sharing">
            <h2>4. Data Sharing and Disclosure</h2>
            <p>We do not sell or rent your personal information to third parties. However, we may share your information in certain circumstances, such as with service providers, for legal reasons, or in the event of business transfers.</p>
          </section>

          <section className="data-security">
            <h2>5. Data Storage and Security</h2>
            <p>We store your data in a secure database and take appropriate measures to protect it from unauthorized access, alteration, disclosure, or destruction. While we strive to protect your information, no method of transmission over the internet or electronic storage is 100% secure.</p>
          </section>

          <section className="user-rights">
            <h2>6. Your Rights and Choices</h2>
            <p>You have the right to access and update your information, opt-out of communications, and manage cookies. For more information on exercising these rights, please contact us at contact@metalstackers.io.</p>
          </section>

          <section className="third-party-sites">
            <h2>7. Third-Party Websites</h2>
            <p>Our website may contain links to third-party websites, including affiliate partners. We are not responsible for the privacy practices or content of these third-party websites. We encourage you to read the privacy policies of any websites you visit.</p>
          </section>

          <section className="childrens-privacy">
            <h2>8. Children's Privacy</h2>
            <p>MetalStackers.io is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.</p>
          </section>

          <section className="changes-to-tos">
            <h2>9. Changes to These Terms of Service</h2>
            <p>We may update these Terms of Service from time to time to reflect changes in our practices or legal obligations. We will notify you of any significant changes by posting the updated policy on our website and updating the effective date.</p>
          </section>

          <section className="contact-us">
            <h2>10. Contact Us</h2>
            <p>If you have any questions or concerns about these Terms of Service or our privacy practices, please contact us at contact@metalstackers.io.</p>
          </section>

          <p className="thank-you">Thank you for being a part of the MetalStackers.io community! Your privacy and trust are important to us, and we are committed to protecting your information.</p>

          <div className="bottom-close-button-container">
            <button className="bottom-close-button" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;