import React from 'react';

function Testimonials() {
  return (
    <section id="testimonials" className="testimonials section">
      <div className="container">
        <h2 className="glow-text">Hear from Our Early Adopters</h2>
        <div className="testimonial-carousel">
          {/* Testimonials will be dynamically added here */}
          <div className="testimonial">
            <p>"MetalStackers has revolutionized the way I manage my precious metals portfolio. The real-time tracking and insights are invaluable!"</p>
            <span className="testimonial-author">- John D., Gold Investor</span>
          </div>
        </div>
        <div className="review-summary">
          <span className="stars">★★★★★</span>
          <p>Rated 4.8/5 by our early testers!</p>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;