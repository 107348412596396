import React from 'react';

function Footer({ openAboutUs, openContribute, openPrivacyPolicy, openTermsOfService, openSupport }) {
  const handleNewsletterSubmit = (e) => {
    e.preventDefault();
    // Implement newsletter signup logic here
    alert('Newsletter signup submitted!');
  };

  return (
    <footer>
      <div className="container">
        <nav>
          <a href="/about" onClick={(e) => { e.preventDefault(); openAboutUs(); }}>About Us</a>
          <a href="/contribute" onClick={(e) => { e.preventDefault(); openContribute(); }}>Contribute</a>
          <a href="/privacy" onClick={(e) => { e.preventDefault(); openPrivacyPolicy(); }}>Privacy Policy</a>
          <a href="/terms" onClick={(e) => { e.preventDefault(); openTermsOfService(); }}>Terms of Service</a>
          <a href="/support" onClick={(e) => { e.preventDefault(); openSupport(); }}>Support</a>
        </nav>
        <div className="social-icons">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter"><i className="fab fa-x"></i></a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><i className="fab fa-linkedin-in"></i></a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
          <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" aria-label="YouTube"><i className="fab fa-youtube"></i></a>
        </div>
        <form className="newsletter-signup neon-border" onSubmit={handleNewsletterSubmit}>
          <input type="email" placeholder="Enter your email for updates" required />
          <button type="submit">Subscribe</button>
        </form>
        <p>&copy; 2025 MetalStackers.io. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;